<template>
  <div t-id="b2b-checkout" class="b2b-checkout">
    <page-header
      :title="t('CHECKOUT_HEADER_TITLE')"
      :link-href="goBackUrl"
      :is-open-pages="false"
    />
    <telia-grid class="b2b-checkout-content">
      <telia-row v-if="basketIsEmpty">
        <telia-col
          class="b2b-checkout-content__card-column b2b-checkout-content__empty-basket"
          t-id="b2b-checkout-content__empty-basket"
          width="12"
        >
          <telia-heading
            t-id="b2b-checkout-content__empty-basket-title"
            tag="h3"
            variant="title-300"
          >
            {{ t("CHECKOUT_BASKET_EMPTY_TITLE") }}
          </telia-heading>
          <telia-link
            v-if="!agreementError"
            t-id="b2b-checkout-continue"
            class="b2b-checkout-content__continue"
            variant="standalone"
            :href="goBackUrl"
            @click.prevent="handleLinkNavigation"
          >
            {{ t("CONTINUE_BUTTON_TEXT") }}
          </telia-link>
          <message-presenter
            class="b2b-checkout-content__empty-basket__message-presenter"
            t-id="b2b-checkout-content__empty-basket__message-presenter"
            v-if="agreementError"
            :messageType="agreementError"
          />
        </telia-col>
      </telia-row>
      <telia-row v-else class="b2b-checkout-content-row">
        <telia-col width="12" width-lg="7">
          <div class="b2b-checkout-content__card-column" width="12">
            <order-contact-details
              t-id="b2b-checkout-contact-details"
              :loading="authorizedOrdererPending"
              :contact-details-error="authorizedOrdererError"
              :name="authorizedOrderer?.name"
              :email="authorizedOrderer?.email"
              :phone-number="authorizedOrderer?.phoneNumber"
              :organization-name="organization?.name"
              :organization-number="organization?.organizationNumber"
            />
            <select-billing-account
              t-id="select-billing-account"
              v-if="!showCreateBillingAccount"
              :has-billing-accounts="hasBillingAccounts"
              :has-no-billing-accounts="hasNoBillingAccounts"
              :has-billing-account-error="hasBillingAccountError"
              :billing-accounts="billingAccounts"
              :billing-accounts-pending="billingAccountsPending"
              :new-billing-account-ids="newBillingAccountIds"
              :selected-billing-account-ids-map="selectedBillingAccountIdsMap"
              :basket="basket"
              :basket-pending="basketPending"
              :selected-billing-account-mode="selectedBillingAccountMode"
              @show-create-billing-account="setShowCreateBillingAccount(true)"
              @select-billing-account-single="setSelectedBillingAccountSingle"
              @select-billing-account-multiple="setSelectedBillingAccountMultiple"
              @change-billing-account-mode="handleBillingAccountModeChange"
            />
            <create-billing-account-component
              t-id="create-billing-account"
              v-else
              :create-billing-account-error="createBillingAccountError"
              :create-billing-account-pending="createBillingAccountPending"
              :payment-due-date-days="paymentDueDateDays"
              :has-letter-invoice-fee="hasLetterInvoiceFee"
              :billing-cycles="billingCycles"
              :default-address="defaultAddress"
              @hide-create-billing-account="setShowCreateBillingAccount(false)"
              @submit-billing-account="createBillingAccount"
            />
          </div>
          <checkout-confirmation
            class="b2b-checkout-content__confirmation-desktop"
            t-id="checkout-confirmation-desktop"
            :frame-agreement-id="basket?.frameAgreementId"
            :terms-and-conditions-link="basket?.generalTermsAndConditions"
            :is-tcad-user="isTcadUser"
            :checkout-pending="checkoutPending"
            :is-checkout-button-disabled="isCheckoutButtonDisabled"
            :checkout-error="checkoutError"
            :is-collective="hasCollectiveItems"
            @checkout-basket="handleCheckoutBasket"
          />
        </telia-col>
        <telia-col width="12" width-lg="5">
          <sticky-container>
            <checkout-card :title="t('BASKET_SUMMARY_TITLE')" bottom-margin="sm" :active="false">
              <b2b-basket
                v-if="!basketPending && !basketError"
                t-id="b2b-checkout-basket"
                class="b2b-checkout-basket"
                :basket.prop="basket"
                :scope-id="scopeId"
                :tscid.attr="tscid"
                hide-go-to-checkout
                @update-basket="(event: CustomEvent) => setBasket(event.detail)"
                @update-basket-pending="setBasketPending"
              />
              <telia-skeleton
                v-if="basketPending && !basketError && !agreementError"
                t-id="b2b-checkout-content__basket-skeleton"
                class="b2b-checkout-content__basket-skeleton"
              />
              <message-presenter v-if="basketError" :messageType="basketError" />
            </checkout-card>
          </sticky-container>
        </telia-col>
        <telia-col width="12" class="b2b-checkout-content__confirmation-mobile">
          <checkout-confirmation
            t-id="checkout-confirmation-mobile"
            :frame-agreement-id="basket?.frameAgreementId"
            :terms-and-conditions-link="basket?.generalTermsAndConditions"
            :is-tcad-user="isTcadUser"
            :checkout-pending="checkoutPending"
            :is-checkout-button-disabled="isCheckoutButtonDisabled"
            :checkout-error="checkoutError"
            :is-collective="hasCollectiveItems"
            @checkout-basket="handleCheckoutBasket"
          />
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref, ComputedRef, computed } from "vue";
import "@telia/b2b-basket";
import { currentLanguage } from "@telia/b2b-i18n";
import type { AuthorizedOrderer, CreateBillingAccountRequestPayload, Lang } from "./typings/types";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import type {
  BasketDTO,
  CheckoutBasketRequestDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";
import type { BroadbandItemDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { translateMixin, translateSetup } from "./locale";
import { getNavigationFunction, getParamsFromUrl } from "./utils/urlUtils";
import PageHeader from "./components/page-header.vue";
import MessagePresenter from "./components/message-presenter.vue";
import CheckoutConfirmation from "./components/checkout-confirmation.vue";
import StickyContainer from "./components/sticky-container.vue";
import SelectBillingAccount from "./components/billing-accounts/select-billing-account.vue";
import CreateBillingAccountComponent from "./components/billing-accounts/create-billing-account.vue";
import OrderContactDetails from "./components/order-contact-details/order-contact-details.vue";
import { useAgreementBillingTerms } from "./composables/useAgreementBillingTerms";
import { useDefaultAddress } from "./composables/useDefaultAddress";
import { useOrganisation } from "./composables/useOrganisation";
import { useTcadUser } from "./composables/useTcadUser";
import {
  BasketItemId,
  BillingAccountId,
  BillingAccountManager,
} from "./utils/BillingAccountManager";
import { BillingAccountMode } from "./typings/types";
import CheckoutCard from "./components/checkout-card.vue";
import { useIsLoggedIn } from "./composables/useIsLoggedIn";
import { getSuccessOrFunnelFail, trackBroadbandProductOrderCompleted } from "./helpers/ga-helper";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import {
  checkoutBasket as checkoutBasketUsingPost,
  getBasketById,
} from "./services/basket-management-service";
import {
  createBillingAccountForTscid,
  getBillingAccountsForTscid,
} from "./services/account-management-service";
import { getIndividualByPartyId } from "./services/party-information-service";
import { toAuthorizedOrderer } from "./utils/user-utils";
import { getAgreement } from "./services/product-listing-service";
import { trackCompleted, trackError } from "./helpers/ga-create-billing-account";
import { navigateToUrl } from "single-spa";
translateSetup();

const t = translateMixin.methods.t;

const urlParams = getParamsFromUrl();
const scopeId = urlParams?.scopeId;
const tscid = urlParams?.tscid;
const basketId = urlParams?.basketId;

const showCreateBillingAccount: Ref<boolean> = ref(false);
const billingAccountManager: Ref<BillingAccountManager> = ref(new BillingAccountManager());
const selectedBillingAccountMode: Ref<BillingAccountMode> = ref(BillingAccountMode.SINGLE);
const agreement: Ref<string> = ref<string>("");
const agreementError = ref<string>("");
const basketPending: Ref<boolean> = ref(true);
const basketError: Ref<"basketError" | null> = ref(null);
const basket: Ref<BasketDTO | undefined> = ref();
const authorizedOrdererPending: Ref<boolean> = ref(true);
const authorizedOrdererError: Ref<boolean> = ref(false);
const authorizedOrderer: Ref<AuthorizedOrderer | null> = ref(null);
const billingAccountsPending: Ref<boolean> = ref(true);
const billingAccountsError: Ref<boolean> = ref(false);
const billingAccounts: Ref<BillingAccountDTO[]> = ref([]);
const createBillingAccountPending: Ref<boolean> = ref(false);
const createBillingAccountError: Ref<"createBillingAccountError" | undefined> = ref();
const newBillingAccountIds: Ref<string[]> = ref([]);
const checkoutError: Ref<"checkoutError" | undefined> = ref();
const checkoutPending: Ref<boolean> = ref(false);

const hasCollectiveItems: ComputedRef<boolean> = computed(() => {
  const items = basket.value?.broadbandItems;
  return items ? items.some((item) => item.fttxAgreementType === "KFÄ3") : false;
});

const basketIsEmpty: ComputedRef<boolean> = computed(() => {
  return (
    !basketPending.value && !basketError.value && (basket.value?.broadbandItems?.length ?? 0) === 0
  );
});

const hasNoBillingAccounts: ComputedRef<boolean> = computed(() => {
  return (
    !billingAccountsPending.value &&
    !billingAccountsError.value &&
    billingAccounts.value.length === 0
  );
});

const hasBillingAccounts: ComputedRef<boolean> = computed(() => {
  return (
    !billingAccountsPending.value && !billingAccountsError.value && billingAccounts.value.length > 0
  );
});

const hasBillingAccountError: ComputedRef<boolean> = computed(() => {
  return !billingAccountsPending.value && billingAccountsError.value;
});

const goBackUrl: ComputedRef<string> = computed(() => {
  return `/foretag/mybusiness/${scopeId}/bestall/${tscid}/bredband`;
});

const selectedBillingAccountIdsMap: ComputedRef<
  Record<BasketItemId, BillingAccountId | null>
> = computed(() => billingAccountManager.value.getProductIdToBillingAccountMap);

const isCheckoutButtonDisabled: ComputedRef<boolean> = computed(() => {
  return (
    isTcadUser.value ||
    basket.value === undefined ||
    checkoutPending.value ||
    basketPending.value ||
    !billingAccountManager.value.allBasketItemsHaveBillingAccount()
  );
});

const { isLoggedIn } = useIsLoggedIn();

if (scopeId && tscid && basketId) {
  fetchData();
}

const { paymentDueDateDays, hasLetterInvoiceFee, billingCycles } = useAgreementBillingTerms(
  scopeId,
  tscid
);

const { defaultAddress } = useDefaultAddress(tscid);

const { organization } = useOrganisation(tscid);

const { isTcadUser } = useTcadUser();

async function fetchData() {
  await fetchAgreement();
  await fetchBasket();
  fetchBillingAccounts();
}

async function fetchAgreement() {
  try {
    agreementError.value = "";
    const response = await getAgreement(scopeId, tscid);
    agreement.value = response?.id;
  } catch {
    agreementError.value = "agreementError";
  }
}

async function fetchBasket() {
  try {
    const basketSuccessOrFunnel = getSuccessOrFunnelFail({
      isLoggedIn: true,
      isOpenPages: false,
      basket: ref(),
      agreementType: agreement.value ? AgreementType.NFA : AgreementType.SA,
    });
    const response = await basketSuccessOrFunnel(
      () => getBasketById(scopeId, tscid, basketId, currentLanguage().toUpperCase() as Lang),
      "Could not get basket"
    );
    if (response) {
      setBasket(response);
      try {
        const individual = await getIndividualByPartyId(basket.value!.authorizedOrderer as string);
        authorizedOrderer.value = toAuthorizedOrderer(individual, tscid);
      } catch (error) {
        authorizedOrdererError.value = true;
      } finally {
        authorizedOrdererPending.value = false;
      }
    }
  } catch {
    basketError.value = "basketError";
  } finally {
    basketPending.value = false;
  }
}

async function fetchBillingAccounts() {
  const successOrFunnelFail = getSuccessOrFunnelFail({
    isLoggedIn: true,
    isOpenPages: false,
    basket: basket,
    agreementType: agreement.value ? AgreementType.NFA : AgreementType.SA,
  });

  try {
    billingAccounts.value = await successOrFunnelFail(
      () => getBillingAccountsForTscid(scopeId, tscid),
      "Could not fetch billing accounts"
    );

    if (billingAccounts.value.length === 0) {
      showCreateBillingAccount.value = true;
    }
  } catch {
    billingAccountsError.value = true;
  } finally {
    billingAccountsPending.value = false;
  }
}

function setShowCreateBillingAccount(isVisible) {
  showCreateBillingAccount.value = isVisible;
}

function setSelectedBillingAccountSingle(billingAccount: BillingAccountDTO) {
  billingAccountManager.value.setBillingAccountForAllBasketItems(billingAccount.id);
}

function setSelectedBillingAccountMultiple(payload: [BillingAccountDTO, BroadbandItemDTO]) {
  const [billingAccount, basketItem] = payload;
  const basketItemId = basketItem.id as string;
  const billingAccountId = billingAccount.id;
  billingAccountManager.value.setBillingAccountForBasketItem(basketItemId, billingAccountId);
}

function handleBillingAccountModeChange(billingAccountMode: BillingAccountMode) {
  selectedBillingAccountMode.value = billingAccountMode;
  billingAccountManager.value.resetSelectedBillingAccounts();
}

function setBasketPending({ detail: updateBasketIsPending }: { detail: boolean }) {
  basketPending.value = updateBasketIsPending;
}

function setBasket(newBasket: BasketDTO) {
  basket.value = newBasket;
  billingAccountManager.value.updateFromBasket(newBasket.broadbandItems ?? []);
}

function handleCheckoutBasket() {
  if (basket.value) {
    const payload = {
      checkoutBasketItems: billingAccountManager.value.getAsCheckoutPayload(),
    };
    checkoutBasket(payload);
  }
}

function handleLinkNavigation() {
  const navigationFunction = getNavigationFunction(() => !basket.value?.frameAgreementId);
  navigationFunction(goBackUrl.value);
}

async function checkoutBasket(payload: CheckoutBasketRequestDTO) {
  try {
    const successOrFunnelFail = getSuccessOrFunnelFail({
      isLoggedIn: true,
      isOpenPages: false,
      basket: basket,
      agreementType: agreement.value ? AgreementType.NFA : AgreementType.SA,
    });

    checkoutError.value = undefined;
    checkoutPending.value = true;
    const response = await successOrFunnelFail(
      () => checkoutBasketUsingPost(scopeId, tscid, basketId, payload),
      "Could not checkout basket"
    );
    trackBroadbandProductOrderCompleted(
      basket.value!,
      response.orderId!,
      !!agreement.value,
      false,
      isLoggedIn.value
    );
    navigateToUrl(
      `/foretag/mybusiness/${scopeId}/bestall/${tscid}/bekraftelse/${response.orderId}`
    );
  } catch {
    checkoutError.value = "checkoutError";
    checkoutPending.value = false;
  }
}

async function createBillingAccount(createBillingAccountData: CreateBillingAccountRequestPayload) {
  try {
    const successOrFunnelFail = getSuccessOrFunnelFail({
      isLoggedIn: true,
      isOpenPages: false,
      basket: basket,
      agreementType: agreement.value ? AgreementType.NFA : AgreementType.SA,
    });

    createBillingAccountPending.value = true;
    createBillingAccountError.value = undefined;
    const createdBillingAccount: BillingAccountDTO = await successOrFunnelFail(
      () => createBillingAccountForTscid(scopeId, tscid, createBillingAccountData),
      "Could not create billing account"
    );
    billingAccounts.value.unshift(createdBillingAccount);
    newBillingAccountIds.value.push(createdBillingAccount.id);
    if (selectedBillingAccountMode.value === BillingAccountMode.SINGLE) {
      setSelectedBillingAccountSingle(createdBillingAccount);
    }
    setShowCreateBillingAccount(false);
    trackCompleted(createBillingAccountData.inputMode === "SEARCH");
  } catch {
    createBillingAccountError.value = "createBillingAccountError";
    trackError(createBillingAccountData.inputMode === "SEARCH");
  } finally {
    createBillingAccountPending.value = false;
  }
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/breakpoints/mixins";
@import "@teliads/components/foundations/grid/variables";
@import "~@teliads/components/foundations/borders/variables";

.b2b-checkout {
  background-color: $telia-white;
  min-height: calc(
    100vh - 282px
  ); // subtract the height of the header and footer (282px) from maximum viewport height
  padding-bottom: $telia-spacing-48;
}

.b2b-checkout-basket {
  display: block;
}

.b2b-checkout-header {
  &__link {
    display: flex;
    align-items: center;
    margin-bottom: $telia-spacing-32;
  }
}

.b2b-checkout-content {
  margin-top: $telia-spacing-16;

  &__header-row {
    margin-bottom: $telia-spacing-64;
  }

  &__card-column {
    margin-bottom: $telia-spacing-24;
  }

  &__empty-basket {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__message-presenter {
      margin-top: $telia-spacing-24;
      width: 100%;
    }
  }

  &__continue {
    margin-top: $telia-spacing-24;
  }

  &__basket {
    padding: 0 $telia-spacing-32;

    &-header {
      margin-bottom: $telia-spacing-24;
    }

    &-skeleton {
      height: 20rem;
      width: 100%;
      margin-bottom: $telia-spacing-24;
    }
  }

  &__confirmation {
    &-desktop {
      margin-top: $telia-spacing-24;
      display: none;

      @media screen and (min-width: $telia-breakpoint-large) {
        display: block;
      }
    }

    &-mobile {
      margin-top: $telia-spacing-24;

      @media screen and (min-width: $telia-breakpoint-large) {
        display: none;
      }
    }
  }
}
</style>
