import { OrganisationSignatoryStatus } from "../typings/types";
import { validateOrganisation } from "../services/organisation-validation-service";
import { Ref, ref } from "vue";
import type { ValidateSignatoryResponseDTO } from "@telia/b2b-rest-client/dist/corp-organisation-validation";

function shouldTrackFunnelFail(response: ValidateSignatoryResponseDTO) {
  return [
    OrganisationSignatoryStatus.MULTIPLE_SIGNATORY,
    OrganisationSignatoryStatus.NOT_VERIFIED,
  ].some((status: string) => status === response.verified);
}

export function useValidateCustomer(
  successOrFunnelFail: <T>(
    restCall: () => Promise<T>,
    errorMessage: string,
    shouldTrackFunnelFail: (x: T) => boolean
  ) => Promise<T>
) {
  const organisationValidationStatus: Ref<OrganisationSignatoryStatus | undefined> = ref<
    OrganisationSignatoryStatus | undefined
  >();
  const validateCustomerIsPending: Ref<boolean> = ref<boolean>(false);

  async function fetchOrganisationValidationStatus(organisationNumber: string) {
    validateCustomerIsPending.value = true;
    try {
      organisationValidationStatus.value = (
        await successOrFunnelFail<ValidateSignatoryResponseDTO>(
          () => validateOrganisation(organisationNumber),
          "Could not continue due to signatory validation rules",
          shouldTrackFunnelFail
        )
      ).verified as OrganisationSignatoryStatus;
    } catch (error) {
      organisationValidationStatus.value = OrganisationSignatoryStatus.CONTACT_SUPPORT;
    } finally {
      validateCustomerIsPending.value = false;
    }
  }

  return {
    organisationValidationStatus,
    validateCustomerIsPending,
    fetchOrganisationValidationStatus,
  };
}
