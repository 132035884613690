<template>
  <div class="checkout-confirmation">
    <telia-notification
      v-if="isTcadUser"
      class="checkout-confirmation__is-tcad-user-error-message"
      t-id="checkout-confirmation__is-tcad-user-error-message"
      heading-tag="h3"
      variant="inline"
      status="information"
    >
      <span slot="heading">{{ t("TCAD_USER_ERROR_TITLE") }}</span>
      <telia-p slot="content">{{ t("TCAD_USER_ERROR_MESSAGE") }}</telia-p>
    </telia-notification>
    <b2x-loading-button
      t-id="checkout-confirmation__checkout-button"
      class="checkout-confirmation__confirm-button"
      full-width
      variant="primary"
      size="lg"
      :is-loading="checkoutPending"
      :disabled="isCheckoutButtonDisabled"
      @click="emit('checkout-basket')"
      >{{ t("CONFIRM_ORDER_BUTTON_TEXT") }}</b2x-loading-button
    >
    <div>
      <template v-if="frameAgreementId">
        <telia-p
          v-if="isCollective"
          class="checkout-confirmation__terms_and_conditions"
          variant="additional-100"
        >
          <span t-id="checkout-confirmation__frame-terms-collective">
            {{
              t("CHECKOUT_TERMS_AND_CONDITIONS_COLLECTIVE", {
                frameAgreementId,
              })
            }}
          </span>
        </telia-p>

        <telia-p
          v-else
          class="checkout-confirmation__terms_and_conditions"
          variant="additional-100"
        >
          <span t-id="checkout-confirmation__frame-terms">
            {{
              t("CHECKOUT_TERMS_AND_CONDITIONS", {
                frameAgreementId,
              })
            }}
          </span>
        </telia-p>
      </template>

      <template v-else>
        <telia-p
          class="checkout-confirmation__terms_and_conditions"
          t-id="checkout-confirmation__service-terms"
        >
          {{ t("CHECKOUT_TERMS_SERVICE_AGREEMENT") }}
          <br />
          <telia-link
            t-id="checkout-confirmation__service-terms-link"
            :href="termsAndConditionsLinkWithFallback"
            target="_blank"
            >{{ t("CHECKOUT_TERMS_SERVICE_AGREEMENT_CONDITIONS_LINK") }}
          </telia-link>
          {{ t("CHECKOUT_TERMS_SERVICE_AGREEMENT_AND") }}
          <telia-link
            t-id="checkout-confirmation__service-policy-link"
            href="/foretag/om/integritetspolicy"
            target="_blank"
            >{{ t("CHECKOUT_TERMS_SERVICE_AGREEMENT_POLICY_LINK") }}
          </telia-link>
        </telia-p>
      </template>
    </div>
    <message-presenter
      class="checkout-confirmation__error-message"
      v-if="checkoutError"
      :messageType="checkoutError"
    />
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";
import { translateMixin, translateSetup } from "../locale";
import MessagePresenter from "./message-presenter.vue";

translateSetup();
const t = translateMixin.methods.t;

interface Props {
  isTcadUser: boolean;
  isCheckoutButtonDisabled: boolean;
  checkoutPending: boolean;
  frameAgreementId?: string;
  checkoutError?: "checkoutError";
  termsAndConditionsLink?: string;
  isCollective?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "checkout-basket"): void;
}>();

const termsAndConditionsLinkWithFallback: ComputedRef<string> = computed(() => {
  return props.termsAndConditionsLink || "/foretag/om/villkor";
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
.checkout-confirmation {
  &__confirm-button {
    margin-bottom: $telia-spacing-12;
  }

  &__is-tcad-user-error-message {
    display: block;
    margin-bottom: $telia-spacing-16;
  }

  &__terms_and_conditions {
    text-align: center;
    color: var(--purpur-color-transparent-black-600);
    margin-top: $telia-spacing-16;
  }

  &__error-message {
    display: block;
    margin-top: $telia-spacing-16;
  }
}
</style>
