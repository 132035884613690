import { corpBasketManagement } from "@telia/b2b-rest-client";
import type {
  CheckoutBasketRequestDTO,
  CheckoutDetachedBasketRequestDTO,
  CheckoutResponseDTO,
  BasketDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";
import type { Lang } from "../typings/types";

export const getBasketById = async (
  scopeId: string,
  tscid: string,
  basketId: string,
  language: Lang
): Promise<BasketDTO> => {
  return corpBasketManagement.BasketControllerService.getBasketById(
    scopeId,
    tscid,
    basketId,
    language
  );
};

export const checkoutBasket = async (
  scopeId: string,
  tscid: string,
  basketId: string,
  billingAccount: CheckoutBasketRequestDTO
): Promise<CheckoutResponseDTO> => {
  return corpBasketManagement.BasketControllerService.checkoutBasket(
    scopeId,
    tscid,
    basketId,
    billingAccount
  );
};

export const checkoutDetachedBasket = async (
  tscid: string,
  basketId: string,
  detachedCheckoutRequest: CheckoutDetachedBasketRequestDTO
): Promise<CheckoutResponseDTO> => {
  return corpBasketManagement.DetachedBasketControllerService.checkoutDetachedBasket(
    tscid,
    basketId,
    detachedCheckoutRequest
  );
};
