import { Ref, ref } from "vue";
import { getLegalAddress } from "../services/address-lookup";
import { toDefaultAddress } from "../utils/address-mapper";
import { DefaultAddress } from "../typings/types";

interface DefaultAddressComposable {
  defaultAddress: Ref<DefaultAddress | undefined>;
}

export function useDefaultAddress(tscid: string): DefaultAddressComposable {
  const defaultAddress: Ref<DefaultAddress | undefined> = ref();
  if (!tscid) return { defaultAddress };
  getLegalAddress(tscid)
    .then((response) => {
      defaultAddress.value = toDefaultAddress(response?.structuredAddressSuggestions?.[0]);
    })
    .catch();
  return {
    defaultAddress,
  };
}
