export const messages = {
  generalError: {
    tId: "b2b-checkout__general-error",
    title: "GENERAL_ERROR_TITLE",
    status: "warning",
    message: "GENERAL_ERROR_MESSAGE",
    accessibleTitle: "ERROR_ACCESSIBLE_TITLE",
  },
  agreementError: {
    tId: "b2b-checkout__general-error",
    title: "AGREEMENT_ERROR_TITLE",
    status: "warning",
    message: "AGREEMENT_ERROR_MESSAGE",
    accessibleTitle: "ERROR_ACCESSIBLE_TITLE",
  },
  basketError: {
    tId: "b2b-checkout-basket__fetch-error",
    title: "BASKET_ERROR_TITLE",
    status: "warning",
    message: "BASKET_ERROR_MESSAGE",
    accessibleTitle: "ERROR_ACCESSIBLE_TITLE",
  },
  checkoutError: {
    tId: "b2b-checkout-confirm__checkout-error",
    title: "CHECKOUT_ERROR_TITLE",
    status: "warning",
    message: "CHECKOUT_ERROR_MESSAGE",
    accessibleTitle: "ERROR_ACCESSIBLE_TITLE",
  },
  createBillingAccountError: {
    tId: "create-billing-account-error",
    title: "CREATE_BILLING_ACCOUNT_ERROR_TITLE",
    status: "warning",
    message: "CREATE_BILLING_ACCOUNT__MESSAGE",
    accessibleTitle: "ERROR_ACCESSIBLE_TITLE",
  },
} as const;
