import { InstallationSettingRequestDTO } from "@telia/b2b-rest-client/dist/corp-basket-management/models/InstallationSettingRequestDTO";
import { CreateBillingAccountRequestDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { DeliveryMethods, InputMode } from "../utils/constants";

export type DeepRequired<T> = { [K in keyof T]: DeepRequired<T[K]> } & Required<T>;

export type Lang = "SV" | "EN";

export interface AuthorizedOrderer {
  name: string;
  email: string | undefined;
  phoneNumber: string | undefined;
  tscid: string;
}
export interface ContactDetailsData {
  isValid: boolean;
  authorizedOrderer: AuthorizedOrderer;
}
export interface Organization {
  name?: string;
  tscid?: string;
  organizationNumber?: string;
  hasAgreement?: boolean;
}

export type CreateBillingAccountRequestPayload = CreateBillingAccountRequestDTO & {
  inputMode: InputModeValues;
};

export type DeliveryMethodKeys = typeof DeliveryMethods;
export type DeliveryMethodValues = (typeof DeliveryMethods)[keyof DeliveryMethodKeys];
export type InputModeKeys = typeof InputMode;
export type InputModeValues = (typeof InputMode)[keyof InputModeKeys];

export enum OrganisationSignatoryStatus {
  VERIFIED = "VERIFIED",
  NOT_VERIFIED = "NOT_VERIFIED",
  MULTIPLE_SIGNATORY = "MULTIPLE_SIGNATORY",
  CONTACT_SUPPORT = "CONTACT_SUPPORT", // internal error status
}

export enum BillingAccountMode {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

export enum SessionStorageKey {
  ORGANISATION_NUMBER = "b2b_checkout_organisation_number",
  B2B_CHECKOUT_DETAILS = "b2b_checkout_details",
  CREATE_MYB_ACCOUNT = "b2b_checkout_create_myb_account",
}

export interface CreateMybAccount {
  createAccount: boolean;
  contactDetails?: ContactDetails;
}

export interface ContactDetails {
  email: string;
  phone: string;
  organisation: OrganisationNumber;
}

export interface OrganisationNumber {
  orgNo: string;
}

export interface B2bCheckoutDetails {
  settings: InstallationSettingRequestDTO[];
  installationPointId: string;
  startDate: string;
}

export interface DefaultAddress {
  pointId?: string;
  streetName?: string;
  streetNumber?: string;
  entrance?: string;
  postalCode?: string;
  city?: string;
  fullAddress?: string;
}
