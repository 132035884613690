<template>
  <FlagProvider v-if="client !== null" :unleash-client="client" :start-client="false">
    <div t-id="b2b-checkout" class="b2b-checkout">
      <B2bCheckoutOpen v-if="openPages" />
      <B2bCheckoutLoggedin v-else />
    </div>
  </FlagProvider>
</template>

<script setup lang="ts">
import "@telia/b2x-loading-button";
import B2bCheckoutLoggedin from "./b2b-checkout-loggedin.vue";
import B2bCheckoutOpen from "./b2b-checkout-open.vue";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm";
import { provide, Ref, readonly, ref } from "vue";
import { isOpenPagesInjectionKey } from "./utils/constants";

interface Props {
  openPages?: boolean;
}

const props = withDefaults(defineProps<Props>(), { openPages: false });
const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId("b2b-checkout");
const isOpenPages: Ref<boolean> = ref<boolean>(props.openPages);
provide(isOpenPagesInjectionKey, readonly(isOpenPages));
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";

.b2b-checkout {
  background: $telia-gray-50;
}
</style>
