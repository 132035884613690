import { InjectionKey, Ref } from "vue";

export const DeliveryMethods = {
  LETTER: "LETTER",
  EMAIL: "EMAIL",
} as const;

export const TeliaRoleTypes = {
  AUTHORIZED_ORDERER: "authorizedOrderer",
} as const;

export const InputMode = {
  MANUAL: "MANUAL",
  SEARCH: "SEARCH",
} as const;

export const isOpenPagesInjectionKey = Symbol() as InjectionKey<Readonly<Ref<boolean>>>;
