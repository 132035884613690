<template>
  <div t-id="single-billing-accounts">
    <label class="sr-only" for="billing-accounts__searchInput"
      >{{ t("BILLING_ACCOUNTS_FILTER_LABEL") }}
    </label>
    <telia-text-input
      t-id="billing-accounts__searchInput"
      class="billing-accounts__searchInput"
      :placeholder="t('BILLING_ACCOUNT_SEARCH')"
      type="search"
      input-id="billing-accounts__searchInput"
      @input="handleSearchInput"
    >
      <telia-icon class="billing-accounts__searchIcon" name="search" />
    </telia-text-input>
    <fieldset class="billing-accounts__fieldset">
      <legend class="sr-only">{{ t("CHECKOUT_BILLING_TITLE") }}</legend>
      <ul class="billing-accounts__list">
        <li
          class="billing-accounts__listItem"
          v-for="billingAccount in billingAccountsToShow"
          :key="billingAccount.id"
        >
          <billing-account-option
            :t-id="`billing-accounts-option-${billingAccount.id}`"
            :checked="isChecked(billingAccount)"
            :billing-account="billingAccount"
            :is-new-account="isNewlyCreatedAccount(billingAccount.id)"
            @input="setSelectedBillingAccount"
          />
        </li>
      </ul>
    </fieldset>
    <div v-if="showMoreButtonVisible" class="billing-accounts__buttonContainer">
      <telia-button
        t-id="billing-accounts__showMoreButton"
        variant="text"
        @click="showAllBillingAccounts"
      >
        {{ t("BILLING_ACCOUNTS_SHOW_MORE_BUTTON") }}
      </telia-button>
    </div>
    <div class="billing-accounts__no-results">
      <telia-p t-id="billing-accounts__no-result" v-if="filteredBillingAccounts.length === 0"
        >{{ t("BILLING_ACCOUNTS_NO_RESULT") }}
      </telia-p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, computed, ComputedRef } from "vue";
import BillingAccountOption from "./billing-account-option.vue";
import { translateSetup, translateMixin } from "../../locale";
import { filterBillingAccounts, getBillingAccountFromId } from "../../utils/filter-utils";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";

translateSetup();
const t = translateMixin.methods.t;

interface Props {
  billingAccounts: BillingAccountDTO[];
  newBillingAccountIds: string[];
  selectedBillingAccountId?: string;
}

const props = withDefaults(defineProps<Props>(), { billingAccounts: () => [] });

const emit = defineEmits<{
  (e: "select-billing-account", selectedBillingAccount: BillingAccountDTO): void;
}>();

const pageSize = 3;
const showAll: Ref<boolean> = ref(false);
const filteredBillingAccounts: Ref<BillingAccountDTO[]> = ref([]);

const showMoreButtonVisible: ComputedRef<boolean> = computed(() => {
  return showAll.value === false && filteredBillingAccounts.value.length > pageSize;
});

const billingAccountsToShow: ComputedRef<BillingAccountDTO[]> = computed(() => {
  return showAll.value === false
    ? filteredBillingAccounts.value.slice(0, pageSize)
    : filteredBillingAccounts.value;
});

function isNewlyCreatedAccount(billingAccountId: string): boolean {
  return props.newBillingAccountIds.includes(billingAccountId);
}

function setSelectedBillingAccount(selectedBillingAccountId: string): void {
  const selectedBillingAccount = getBillingAccountFromId(
    props.billingAccounts,
    selectedBillingAccountId
  );
  emit("select-billing-account", selectedBillingAccount as BillingAccountDTO);
}

function isChecked(billingAccount: BillingAccountDTO): boolean {
  return billingAccount.id === props.selectedBillingAccountId;
}

function handleSearchInput(event: Event): void {
  const searchTerm = (event.target as HTMLInputElement).value;
  filterBillingAccountsBySearchTerm(searchTerm);
}

function filterBillingAccountsBySearchTerm(searchTerm: string): void {
  filteredBillingAccounts.value = filterBillingAccounts(
    props.billingAccounts,
    getBillingAccountFromId(props.billingAccounts, props.selectedBillingAccountId),
    searchTerm
  );
}

function showAllBillingAccounts(): void {
  showAll.value = true;
}

filterBillingAccountsBySearchTerm("");
if (props.selectedBillingAccountId) {
  setSelectedBillingAccount(props.selectedBillingAccountId);
}
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/tokens";

.billing-accounts {
  &__searchInput {
    display: block;
    position: relative;
    margin-bottom: $telia-spacing-16;

    input[type="search"] {
      margin-top: 0;
      padding-right: $telia-spacing-48;
    }
  }

  &__searchIcon {
    position: absolute;
    right: $telia-spacing-12;
    top: 50%;
    transform: translateY(-50%);
  }

  &__no-results {
    display: flex;
    justify-content: center;
  }

  &__fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
  }

  &__list {
    list-style-type: none;
    max-height: 42rem;
    overflow: auto;
    padding: $telia-spacing-8;
  }

  &__listItem + &__listItem {
    margin-top: $telia-spacing-16;
  }

  &__buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: $telia-spacing-12;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>
