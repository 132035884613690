<template>
  <div class="billing-account-option">
    <b2x-radio-card
      name="billingAccount"
      :id="`billingAccountRadio-${billingAccount.id}`"
      :label="`${billingAccount.id} / ${billingAccount.reference}`"
      :value="billingAccount.id"
      :checked="checked"
      @change="handleInput"
    >
      <div v-if="checked" class="billing-account-option__additionalInformation">
        <telia-badge
          t-id="billing-account-option__new-badge"
          class="billing-account-option__new-badge"
          v-if="isNewAccount"
          variant="information"
          >{{ t("BILLING_ACCOUNT_OPTION_NEW_ACCOUNT") }}
        </telia-badge>
        <telia-p
          v-for="(informationText, idx) in billingAccountInformation"
          class="billing-account-option__text"
          variant="paragraph-100"
          :key="idx"
          >{{ informationText }}
        </telia-p>
        <telia-p
          t-id="billing-account-option__deliveryMethod"
          class="billing-account-option__text"
          variant="paragraph-100"
        >
          {{ deliveryMethod }}
        </telia-p>
      </div>
    </b2x-radio-card>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import { getBillingAccountInformation } from "../../utils/billing-account-information-extraction";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import "@telia/b2x-radio-card";
import { identity } from "../../utils/fp";

translateSetup();
const t = translateMixin.methods.t;

interface Props {
  billingAccount: BillingAccountDTO;
  checked: boolean;
  isNewAccount: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "input", billingAccountId: string): void;
}>();
const deliveryMethodTranslationMap = {
  LETTER: "BILLING_ACCOUNTS_DELIVERY_METHOD_LETTER",
  EMAIL: "BILLING_ACCOUNTS_DELIVERY_METHOD_EMAIL",
  SMS: "BILLING_ACCOUNTS_DELIVERY_METHOD_SMS",
} as const;

const deliveryMethod: ComputedRef<string> = computed(() => {
  return t(deliveryMethodTranslationMap[props.billingAccount.deliveryMethod]);
});

const billingAccountInformation: ComputedRef<string[]> = computed(() => {
  return getBillingAccountInformation(props.billingAccount);
});

function handleInput() {
  emit("input", props.billingAccount.id);
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/mixins";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/typography/variables";
@import "~@teliads/components/foundations/typography/mixins";

.billing-account-option {
  $self: &;
  position: relative;

  &__additionalInformation {
    margin-top: $telia-spacing-4;
  }

  &__new-badge {
    display: block;
    margin-bottom: $telia-spacing-8;
  }

  &__text + &__text {
    margin-top: $telia-spacing-2;
  }
}
</style>
