import { corpBillingAccounts } from "@telia/b2b-rest-client";
import { CreateBillingAccountRequestPayload } from "../typings/types";
import type { CreateBillingAccountRequestDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { DeliveryMethods } from "../utils/constants";

export const getBillingAccountsForTscid = (scopeId, tscid) => {
  return corpBillingAccounts.AccountManagementControllerService.getBillingAccountsForTscid1(
    scopeId,
    tscid
  );
};

export const createBillingAccountForTscid = (
  scopeId: string,
  tscid: string,
  createBillingAccountRequestPayload: CreateBillingAccountRequestPayload
) => {
  const createBillingAccountRequestDTO: CreateBillingAccountRequestDTO = {
    address: {
      city: createBillingAccountRequestPayload.address.city,
      postalCode: createBillingAccountRequestPayload.address.postalCode,
      streetName: createBillingAccountRequestPayload.address.streetName,
      streetNumber: createBillingAccountRequestPayload.address.streetNumber,
      entrance: createBillingAccountRequestPayload.address.entrance,
      additionalAddressInformation:
        createBillingAccountRequestPayload.address.additionalAddressInformation,
    },
    reference: createBillingAccountRequestPayload.reference,
    deliveryMethod: createBillingAccountRequestPayload.deliveryMethod,
    billingCycle: createBillingAccountRequestPayload.billingCycle,
  };

  if (createBillingAccountRequestPayload.deliveryMethod === DeliveryMethods.EMAIL) {
    createBillingAccountRequestDTO.emailAddress = createBillingAccountRequestPayload.emailAddress;
  }
  return corpBillingAccounts.AccountManagementControllerService.createBillingAccountForTscid(
    scopeId,
    tscid,
    createBillingAccountRequestDTO
  );
};

export const createBillingAccountForTscidOpen = (
  tscid: string,
  createBillingAccountRequestPayload: CreateBillingAccountRequestPayload
) => {
  const createBillingAccountRequestDTO: CreateBillingAccountRequestDTO = {
    address: {
      city: createBillingAccountRequestPayload.address.city,
      postalCode: createBillingAccountRequestPayload.address.postalCode,
      streetName: createBillingAccountRequestPayload.address.streetName,
      streetNumber: createBillingAccountRequestPayload.address.streetNumber,
      entrance: createBillingAccountRequestPayload.address.entrance,
      additionalAddressInformation:
        createBillingAccountRequestPayload.address.additionalAddressInformation,
    },
    reference: createBillingAccountRequestPayload.reference,
    deliveryMethod: createBillingAccountRequestPayload.deliveryMethod,
    billingCycle: createBillingAccountRequestPayload.billingCycle,
  };

  return corpBillingAccounts.AccountManagementControllerService.createBillingAccountForTscid1(
    tscid,
    createBillingAccountRequestDTO
  );
};
