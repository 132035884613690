import {
  trackIdentifyEvent,
  AgreementType,
  IdentifyState,
  IdentifyType,
  Salesflow,
  trackPurchase,
  trackFunnelFail,
  trackExpressCheckoutEvent,
  ExpressCheckoutOption,
} from "@telia/b2b-ecommerce-tracking";
import { BasketDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { DetachedBasketDTO } from "@telia/b2b-rest-client/dist/corp-open-basket-management";
import {
  BROADBAND_BRAND,
  BROADBAND_CATEGORY,
  BROADBAND_LISTNAME,
  BROADBAND_SUBCATEGORY,
  BROADBAND_VARIANT,
  BROADBAND_CUPON_CODE,
  BROADBAND_NEW,
} from "./ga-constants";
import { TrackFunnelFailArgs } from "@telia/b2b-ecommerce-tracking/dist/interfaces";
import { Ref, unref } from "vue";

export { IdentifyState, IdentifyType };

export function trackBroadbandProductOrderCompleted(
  basket: DetachedBasketDTO | BasketDTO,
  orderId: string,
  isFrameAgreement: boolean,
  isOpenPages: boolean,
  isLoggedIn: boolean
) {
  trackPurchase({
    productList: buildTrackProductOrderCompletedPayload(basket),
    listName: BROADBAND_LISTNAME,
    agreementType: isFrameAgreement ? AgreementType.NFA : AgreementType.SA,
    numberOfBillingAccounts: 1,
    orderId: orderId,
    salesflow: isOpenPages ? Salesflow.OP : Salesflow.MB,
    totalPrice: basket.recurringPrice!,
    isOpenPages,
    isLoggedIn,
  });
}

export function trackBankIdInitiate() {
  trackExpressCheckoutEvent(ExpressCheckoutOption.IDENTIFY_BANKID);
}

export function trackBankIdVerified() {
  trackExpressCheckoutEvent(ExpressCheckoutOption.VERIFIED_BANKID);
}

export function trackOrganisationIdentification(type: IdentifyType) {
  trackIdentifyEvent(IdentifyState.CLICK, type);
}

function buildTrackProductOrderCompletedPayload(basket) {
  return basket.broadbandItems.map(toDetailedProduct(basket));
}

function toDetailedProduct(basket) {
  return (product) => ({
    id: product.productId,
    name: product.name,
    price: basket.recurringPrice,
    category: BROADBAND_CATEGORY,
    subCategory: BROADBAND_SUBCATEGORY,
    brand: BROADBAND_BRAND,
    quantity: "1",
    variant: BROADBAND_VARIANT,
    recurringPrice: basket.recurringPrice,
    onetimePrice: basket.onetimePrice,
    couponCode: BROADBAND_CUPON_CODE,
    newOrExtend: BROADBAND_NEW,
  });
}

interface TrackFunnelFailRefs {
  isLoggedIn: boolean | Ref<boolean>;
  isOpenPages: boolean;
  basket: Ref<BasketDTO | undefined>;
  agreementType: AgreementType;
}

function getFunnelFailOptions(funnelFailOptionRefs: TrackFunnelFailRefs, errorMessage: string) {
  const funnelFailOptions: TrackFunnelFailArgs = {
    isLoggedIn: unref(funnelFailOptionRefs.isLoggedIn),
    isOpenPages: funnelFailOptionRefs.isOpenPages,
    totalPrice: funnelFailOptionRefs.basket.value?.recurringPrice ?? "0",
    agreementType: funnelFailOptionRefs.agreementType,
    errorMessage,
  };
  return funnelFailOptions;
}

function alwaysFalse<T>(_: T): boolean {
  return false;
}

export function getSuccessOrFunnelFail(funnelFailOptionRefs: TrackFunnelFailRefs) {
  return async function successOrFunnelFail<T>(
    restCall: () => Promise<T>,
    errorMessage: string,
    shouldTrackFunnelFail: (x: T) => boolean = alwaysFalse
  ): Promise<T> {
    try {
      const response = await restCall();
      if (shouldTrackFunnelFail(response)) {
        const funnelFailOptions = getFunnelFailOptions(funnelFailOptionRefs, errorMessage);
        trackFunnelFail(funnelFailOptions);
      }
      return response;
    } catch (e) {
      const funnelFailOptions = getFunnelFailOptions(funnelFailOptionRefs, errorMessage);
      trackFunnelFail(funnelFailOptions);
      throw e;
    }
  };
}
