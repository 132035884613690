import * as analytics from "@telia/b2b-web-analytics-wrapper";

const openPagesCategory =
  analytics.category.INVOICE_ACCOUNTS && "B2B_OP_" + analytics.category.INVOICE_ACCOUNTS;

function trackingFunction(isOpenPages: boolean) {
  return isOpenPages ? analytics.trackEventOpenPage : analytics.trackEvent;
}

function getCategory(isOpenPages: boolean) {
  return isOpenPages ? openPagesCategory : analytics.category.INVOICE_ACCOUNTS;
}

export function trackInitiated(isOpenPages = false): void {
  const track = trackingFunction(isOpenPages);
  const category = getCategory(isOpenPages);

  track(category, analytics.action.INITIATED, analytics.label.CREATE_BILLING_ACCOUNT);
}

export function trackInputModeChanged(isSearch: boolean, isOpenPages = false): void {
  const track = trackingFunction(isOpenPages);
  const category = getCategory(isOpenPages);

  track(category, analytics.action.CLICK, isSearch ? "address_search" : "address_manual");
}

export function trackCompleted(isSearch: boolean, isOpenPages = false): void {
  const track = trackingFunction(isOpenPages);
  const category = getCategory(isOpenPages);

  track(
    category,
    analytics.action.COMPLETED,
    `${analytics.label.CREATE_BILLING_ACCOUNT}; ${isSearch ? "address_search" : "address_manual"}`
  );
}

export function trackError(isSearch: boolean, isOpenPages = false): void {
  const track = trackingFunction(isOpenPages);
  const category = getCategory(isOpenPages);

  track(
    category,
    analytics.action.ERROR,
    `${analytics.label.CREATE_BILLING_ACCOUNT}; ${isSearch ? "address_search" : "address_manual"}`
  );
}
