import { ComputedRef, ref, Ref } from "vue";
import { checkoutDetachedBasket as checkoutDetachedBasketUsingPost } from "../services/basket-management-service";
import { trackBroadbandProductOrderCompleted } from "../helpers/ga-helper";
import type { CheckoutDetachedBasketRequestDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { DetachedBasketDTO } from "@telia/b2b-rest-client/dist/corp-open-basket-management";

interface CheckoutDetachedBasketComposable {
  error: Ref<"checkoutError" | undefined>;
  pending: Ref<boolean>;
  checkoutDetachedBasket: (
    basket: DetachedBasketDTO,
    payload: CheckoutDetachedBasketRequestDTO,
    emitGoToConfirmation: (orderId: string) => void
  ) => Promise<void>;
}

export function useCheckoutDetachedBasket(
  tscid: ComputedRef<string | null>,
  basketId,
  isLoggedIn: Ref<boolean>,
  successOrFunnelFail: <T>(restCall: () => Promise<T>, errorMessage: string) => Promise<T>
): CheckoutDetachedBasketComposable {
  const error: Ref<"checkoutError" | undefined> = ref();
  const pending: Ref<boolean> = ref(false);

  async function checkoutDetachedBasket(
    basket: DetachedBasketDTO,
    payload: CheckoutDetachedBasketRequestDTO,
    emitGoToConfirmation: (orderId: string) => void
  ) {
    try {
      error.value = undefined;
      pending.value = true;
      if (tscid.value) {
        const response = await successOrFunnelFail(
          () => checkoutDetachedBasketUsingPost(tscid.value!, basketId, payload),
          "Could not checkout basket"
        );
        trackBroadbandProductOrderCompleted(
          basket,
          response.orderId!,
          false,
          true,
          isLoggedIn.value
        );
        response.orderId && emitGoToConfirmation(response.orderId);
      }
    } catch {
      error.value = "checkoutError";
      pending.value = false;
    }
  }

  return {
    pending,
    error,
    checkoutDetachedBasket,
  };
}
