<template>
  <checkout-card
    :title="t('CHECKOUT_CONTACT_TITLE')"
    class="order-contact-details__container"
    bottom-margin="lg"
    :active="false"
  >
    <div class="order-contact-details-skeleton" t-id="contact-details-skeleton" v-if="loading">
      <telia-skeleton
        class="order-contact-details-skeleton__paragraph contact-details-skeleton__paragraph--sm"
      />
      <telia-skeleton
        class="order-contact-details-skeleton__paragraph contact-details-skeleton__paragraph--lg"
      />
      <telia-skeleton
        class="order-contact-details-skeleton__paragraph contact-details-skeleton__paragraph--md"
      />
    </div>
    <div t-id="order-contact-details" v-else>
      <telia-notification
        v-if="contactDetailsError"
        t-id="order-contact-details__error-message"
        variant="inline"
        status="warning"
        heading-tag="h3"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">{{ t("CHECKOUT_CONTACT_ERROR_TITLE") }}</span>
        <span slot="content">
          <telia-p>{{ t("CHECKOUT_CONTACT_ERROR_DESCRIPTION") }}</telia-p>
        </span>
      </telia-notification>
      <div t-id="order-contact-details__contact-container" v-if="!contactDetailsError">
        <telia-p>
          <span class="order-contact-details__user" t-id="order-contact-details__user">
            {{ name }}
          </span>
        </telia-p>
        <telia-p t-id="order-contact-details__information">{{ contactInformationLine }}</telia-p>
        <telia-p
          t-id="order-contact-details__organization"
          v-if="organizationName && organizationNumber"
        >
          {{ organizationName }}, {{ formattedOrganizationNumber }}
        </telia-p>
      </div>
    </div>
  </checkout-card>
</template>

<script setup lang="ts">
import { ComputedRef, computed, ref } from "vue";
import CheckoutCard from "../checkout-card.vue";
import { translateMixin, translateSetup } from "../../locale";
import { isValidEmailPattern, isValidMobileNumberPattern } from "../../utils/validation";
import { removeEmptyValues } from "../../utils/fp";
import { formatOrganizationNumber, formatPhoneNumber } from "../../utils/formatting-utils";

interface Props {
  loading: boolean;
  contactDetailsError?: boolean;
  name?: string;
  email?: string;
  phoneNumber?: string;
  organizationName?: string;
  organizationNumber?: string;
}

const props = defineProps<Props>();

translateSetup();
const t = translateMixin.methods.t;

const contactInformationLine: ComputedRef<String> = computed(() => {
  return [props.email, formatPhoneNumber(props.phoneNumber)].filter(removeEmptyValues).join(", ");
});
const formattedOrganizationNumber: ComputedRef<String | undefined> = computed(() => {
  return formatOrganizationNumber(props.organizationNumber || "");
});
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/typography/variables";

.order-contact-details {
  &__container {
    margin-bottom: $telia-spacing-24;
  }

  &__user {
    font-weight: $telia-typography-weight-medium;
  }

  &__message-presenter {
    margin-top: $telia-spacing-12;
  }

  &__field-container + &__field-container {
    margin-top: $telia-spacing-32;
  }
}
</style>
