<template>
  <checkout-card :title="t('CREATE_BILLING_ACCOUNT_TITLE')" :key="componentRef">
    <div class="create-billing-account">
      <telia-form
        t-id="billing-account-form"
        class="billing-account-form"
        @submit.prevent="handleCreateBillingAccount"
        novalidate
      >
        <div class="billing-account-form__field-container">
          <telia-label html-for="referenceInputId">
            {{ t("BILLING_ACCOUNT_FORM_NAME_LABEL") }}
          </telia-label>
          <div class="billing-account-form__reference-input-container">
            <telia-text-input
              t-id="billing-account-form__reference"
              class="billing-account-form__reference-input"
              input-id="referenceInputId"
              name="reference"
              type="text"
              required
              :required-error-message="t('BILLING_ACCOUNT_FORM_FIELD_IS_REQUIRED')"
              :value="reference"
              @input="reference = $event.target.value"
            />
            <div class="billing-account-form__reference-tooltip-container">
              <b2x-tooltip
                t-id="billing-account-form__reference-tooltip"
                class="billing-account-form__reference-tooltip"
                :content="t('BILLING_ACCOUNT_FORM_REFERENCE_TOOLTIP')"
                placement="right"
              />
            </div>
          </div>
        </div>
        <div class="billing-account-form__field-container">
          <address-input
            t-id="billing-account-form__address"
            :default-address="defaultAddress"
            @address-selected="setSelectedAddress"
            @input-mode-changed="handleAddressInputModeChanged"
          />
        </div>
        <div v-if="!isOpenPages" class="billing-account-form__field-container">
          <delivery-method
            :selected-delivery-method="selectedDeliveryMethod"
            @change-delivery-method="selectedDeliveryMethod = $event"
          />
          <telia-text-input
            v-show="selectedDeliveryMethod === DeliveryMethods.EMAIL"
            class="billing-account-form__email-input"
            t-id="billing-account-form__email-input"
            type="email"
            autocomplete="email"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
            :pattern-error-message="t('BILLING_ACCOUNT_FORM_EMAIL_LABEL_INVALID')"
            required
            :required-error-message="t('BILLING_ACCOUNT_FORM_FIELD_IS_REQUIRED')"
            :label="t('BILLING_ACCOUNT_FORM_EMAIL_LABEL')"
            :value="emailAddress"
            @input="emailAddress = $event.target.value"
          />
          <telia-p v-if="selectedDeliveryMethod === DeliveryMethods.EMAIL">
            {{ t("BILLING_ACCOUNT_FORM_EMAIL_POLICY") }}
            <telia-link
              t-id="billing-account-form__email-policy-link"
              href="/foretag/om/integritetspolicy"
              target="_blank"
              >{{ t("BILLING_ACCOUNT_FORM_EMAIL_POLICY_LINK") }}
            </telia-link>
          </telia-p>
          <telia-p
            t-id="create-billing-account__letter-invoice-fee"
            v-if="showLetterInvoiceFeeText"
          >
            {{ t("BILLING_ACCOUNT_FORM_LETTER_INVOICE_FEE") }}
          </telia-p>
        </div>

        <div
          v-if="isOpenPages"
          class="billing-account-form__field-container"
          t-id="billing-account-form__open-pages-delivery-disclaimer"
        >
          {{ t("mybusinessotpcheckout.openPagesInvoiceDeliveryDisclaimer") }}
        </div>

        <div class="billing-account-form__field-container">
          <billing-cycle
            t-id="create-billing-account__billing-cycle"
            :billing-cycles="billingCycles"
            :selected-billing-cycle="selectedBillingCycle"
            @change-billing-cycle="selectedBillingCycle = $event"
          ></billing-cycle>

          <telia-p t-id="create-billing-account__billing-terms" v-if="paymentDueDateDays">
            {{ billingTermsText }}
          </telia-p>
        </div>

        <div class="billing-account-form__action-container">
          <message-presenter
            v-if="createBillingAccountError"
            :message-type="createBillingAccountError"
          />
          <div class="billing-account-form__action-container-buttons">
            <telia-button
              t-id="billing-account-form__submit-button"
              class="billing-account-form__button"
              variant="primary"
              type="submit"
              :disabled="createButtonDisabled"
            >
              {{ t("BILLING_ACCOUNT_FORM_CREATE_ACCOUNT") }}
            </telia-button>
            <telia-button
              t-id="billing-account-form__cancel-button"
              class="billing-account-form__button"
              variant="secondary"
              type="button"
              @click="emit('hide-create-billing-account')"
              >{{ t("BILLING_ACCOUNT_FORM_CANCEL") }}
            </telia-button>
          </div>
        </div>
      </telia-form>
    </div>
  </checkout-card>
</template>

<script setup lang="ts">
import { ref, Ref, ComputedRef, computed, watch, inject } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import CheckoutCard from "../checkout-card.vue";
import AddressInput from "./address-input.vue";
import DeliveryMethod from "./delivery-method.vue";
import MessagePresenter from "../message-presenter.vue";
import { DeliveryMethods, InputMode, isOpenPagesInjectionKey } from "../../utils/constants";
import { formIsValid } from "../../utils/validation";
import { trackInitiated, trackInputModeChanged } from "../../helpers/ga-create-billing-account";
import type {
  CreateBillingAccountRequestPayload,
  DefaultAddress,
  DeliveryMethodValues,
  InputModeValues,
} from "../../typings/types";
import type { AddressRequestDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import "@telia/b2x-tooltip";
import BillingCycle from "./billing-cycle.vue";
import { toAddressRequestDTO } from "../../utils/address-mapper";

const isOpenPages = inject(isOpenPagesInjectionKey) as Readonly<Ref<boolean>>;

trackInitiated(isOpenPages.value);

const componentRef = ref(0);
loadTranslations();
async function loadTranslations() {
  await translateSetup(["mybusinessotpcheckout"]);
  componentRef.value++;
}

const t = translateMixin.methods.t;

interface Props {
  createBillingAccountError?: string;
  createBillingAccountPending: boolean;
  hasLetterInvoiceFee?: boolean;
  paymentDueDateDays?: number;
  billingCycles: string[];
  defaultAddress?: DefaultAddress;
  defaultReference?: string;
  isCreateBillingAccountDisabled?: boolean;
}

const emit = defineEmits<{
  (e: "submit-billing-account", payload: CreateBillingAccountRequestPayload): void;
  (e: "hide-create-billing-account"): void;
}>();
const props = withDefaults(defineProps<Props>(), {
  isCreateBillingAccountDisabled: false,
  paymentDueDateDays: undefined,
  hasLetterInvoiceFee: false,
});

const reference: Ref<string> = ref(props.defaultReference || "");

const emailAddress: Ref<string> = ref("");
const selectedDeliveryMethod: Ref<DeliveryMethodValues> = isOpenPages.value
  ? ref(DeliveryMethods.LETTER)
  : ref(DeliveryMethods.EMAIL);
const selectedBillingCycle: Ref<string | undefined> = ref(props.billingCycles[0]);
const currentInputMode: Ref<InputModeValues> = ref(InputMode.SEARCH);
const address: Ref<AddressRequestDTO | null> = ref(
  toAddressRequestDTO(props.defaultAddress) ?? null
);
const createButtonDisabled: ComputedRef<boolean> = computed(() => {
  return (
    !formIsValid(
      selectedDeliveryMethod.value,
      reference.value,
      address.value,
      emailAddress.value
    ) ||
    props.createBillingAccountPending ||
    props.isCreateBillingAccountDisabled
  );
});

watch(
  () => props.billingCycles,
  () => {
    selectedBillingCycle.value = props.billingCycles[0];
  },
  { deep: true }
);

const showLetterInvoiceFeeText: ComputedRef<boolean> = computed(() => {
  return selectedDeliveryMethod.value === DeliveryMethods.LETTER && props.hasLetterInvoiceFee;
});

const billingTermsText: ComputedRef<string> = computed(() => {
  return t("BILLING_ACCOUNT_FORM_BILLING_PERIODICITY", {
    paymentDueDateDays: props.paymentDueDateDays as number,
  });
});

function setSelectedAddress(selectedAddress: AddressRequestDTO): void {
  address.value = selectedAddress;
}

function handleAddressInputModeChanged(mode: InputModeValues): void {
  currentInputMode.value = mode;
  trackInputModeChanged(mode === InputMode.SEARCH, isOpenPages.value);
}

function handleCreateBillingAccount() {
  emit("submit-billing-account", {
    reference: reference.value,
    address: address.value as AddressRequestDTO,
    deliveryMethod: selectedDeliveryMethod.value,
    emailAddress: emailAddress.value,
    inputMode: currentInputMode.value,
    billingCycle: selectedBillingCycle.value,
  });
}
</script>

<style lang="scss">
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.billing-account-form {
  display: block;
  padding-top: $telia-spacing-8;

  &__email-input {
    margin-bottom: $telia-spacing-16;
  }

  > form {
    padding-bottom: 0;
  }

  &__action-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: $telia-spacing-32;

    &-buttons {
      display: flex;
    }
  }

  &__field-container + &__field-container {
    margin-top: $telia-spacing-32;
  }

  &__reference-input-container {
    position: relative;
    padding-right: $telia-spacing-48;

    .telia-label {
      display: none;
    }
  }

  &__reference-tooltip-container {
    position: absolute;
    top: $telia-spacing-16;
    right: 0;
  }

  &__reference-tooltip {
    padding: $telia-spacing-16;
  }

  &__button + &__button {
    margin-left: $telia-spacing-12;
  }
}
</style>
