import { getIndividualByTscid, getOrganisation } from "../services/party-information-service";
import { getUserFromToken } from "../services/user-account-service";
import { ref } from "vue";
import {
  AuthorizedOrderer,
  OrganisationNumber,
  Organization,
  SessionStorageKey,
} from "../typings/types";
import { getScopeForLoggedInUser } from "../services/scope-information-service";
import { getBasketIdFromUrl } from "../utils/urlUtils";
import { getAgreementForExpressCheckout } from "../services/product-listing-service";
import { trackBankIdVerified } from "../helpers/ga-helper";
import { AuthorizedOrdererError, toAuthorizedOrderer } from "../utils/user-utils";

export function useLoggedInUser(fetchOrganisationValidationStatus: (orgNr: string) => void) {
  const loggedInUser = ref<AuthorizedOrderer | undefined>();
  const organization = ref<Organization | undefined>();
  const loggedInUserPending = ref<boolean>(true);
  const loggedInUserError = ref<string | undefined>();

  (async () => {
    function missingInPim(e) {
      return e.status === 403;
    }

    function notAuthorizedOrderer(e) {
      return e instanceof AuthorizedOrdererError;
    }

    try {
      const userScope = await getScopeForLoggedInUser();
      if (userScope.customerScope != null) {
        window.location.href = `/foretag/bredband/till-kassan/${getBasketIdFromUrl()}`;
        return;
      }
      const user = await getUserFromToken();
      if (user) {
        trackBankIdVerified();
      }

      const storedFormData = window.sessionStorage.getItem(SessionStorageKey.ORGANISATION_NUMBER);
      if (!storedFormData) {
        return;
      }
      const organisationNumber = JSON.parse(storedFormData) as OrganisationNumber;

      fetchOrganisationValidationStatus(organisationNumber.orgNo);

      const organisationResponse = await getOrganisation(organisationNumber.orgNo);

      let authorizedOrderer: AuthorizedOrderer | undefined;
      try {
        const individual = await getIndividualByTscid(organisationResponse.tscid || "");
        authorizedOrderer = toAuthorizedOrderer(individual, organisationResponse.tscid || "");
      } catch (e) {
        if (missingInPim(e) || notAuthorizedOrderer(e)) {
          // Pass through
        } else {
          throw e;
        }
      }
      const agreement = await getAgreementForExpressCheckout(organisationResponse.tscid || "");

      loggedInUser.value = {
        name: user.name || "",
        email: authorizedOrderer?.email,
        phoneNumber: authorizedOrderer?.phoneNumber,
        tscid: organisationResponse.tscid || "",
      };
      organization.value = {
        tscid: organisationResponse.tscid,
        organizationNumber: organisationNumber.orgNo,
        name: organisationResponse.name,
        hasAgreement: !!agreement?.id,
      };
    } catch (error) {
      if ((error as { status: number }).status !== 401) {
        loggedInUserError.value = "generalError";
      }
    } finally {
      loggedInUserPending.value = false;
    }
  })();

  return { loggedInUser, organization, loggedInUserPending, loggedInUserError };
}
