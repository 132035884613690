import { ref, Ref } from "vue";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import type { CreateBillingAccountRequestPayload } from "../typings/types";
import { BillingAccountMode } from "../typings/types";
import {
  createBillingAccountForTscid,
  createBillingAccountForTscidOpen,
} from "../services/account-management-service";
import { trackCompleted, trackError } from "../helpers/ga-create-billing-account";
import { BillingAccountManager } from "../utils/BillingAccountManager";

interface CreateBillingAccountComposable {
  pending: Ref<boolean>;
  error: Ref<string | undefined>;
  newBillingAccountIds: Ref<string[]>;
  createBillingAccount: (
    createBillingAccountData: CreateBillingAccountRequestPayload
  ) => Promise<void>;
}

export function useOpenCreateBillingAccount(
  tscid: Ref<string | null>,
  billingAccounts: Ref<BillingAccountDTO[]>,
  selectBillingAccount: (selectedBillingAccount: BillingAccountDTO) => void,
  setShowCreateBillingAccount: (isVisible: boolean) => void,
  successOrFunnelFail: <T>(restCall: () => Promise<T>, errorMessage: string) => Promise<T>
): CreateBillingAccountComposable {
  const pending: Ref<boolean> = ref(false);
  const error: Ref<"createBillingAccountError" | undefined> = ref();
  const newBillingAccountIds: Ref<string[]> = ref([]);
  async function createBillingAccount(
    createBillingAccountData: CreateBillingAccountRequestPayload
  ) {
    try {
      pending.value = true;
      error.value = undefined;
      const createdBillingAccount: BillingAccountDTO = await successOrFunnelFail(
        () => createBillingAccountForTscidOpen(tscid.value || "", createBillingAccountData),
        "Could not create billing account"
      );
      billingAccounts.value.unshift(createdBillingAccount);
      newBillingAccountIds.value.push(createdBillingAccount.id);
      selectBillingAccount(createdBillingAccount);
      setShowCreateBillingAccount(false);
      trackCompleted(createBillingAccountData.inputMode === "SEARCH", true);
    } catch {
      error.value = "createBillingAccountError";
      trackError(createBillingAccountData.inputMode === "SEARCH", true);
    } finally {
      pending.value = false;
    }
  }

  return {
    pending,
    error,
    newBillingAccountIds,
    createBillingAccount,
  };
}
