import { corpProductListing } from "@telia/b2b-rest-client";
import { FrameAgreementUI } from "@telia/b2b-rest-client/dist/corp-product-listing";

export const getAgreementForExpressCheckout = async (tscid: string): Promise<FrameAgreementUI> => {
  return corpProductListing.AgreementControllerService.getAgreementForExpressCheckout(tscid);
};

export const getAgreement = async (scopeId: string, tscid: string): Promise<FrameAgreementUI> => {
  return corpProductListing.AgreementControllerService.getAgreement(scopeId, tscid);
};
