export function resolve(obj, path) {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj);
}

function isEqual(a: unknown, b: unknown): boolean {
  return a === b;
}

function getPropFromObj<T, U extends keyof T>(prop: U, obj: T): T[U] {
  return obj[prop];
}

export function curry(fn, arity = fn.length) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (function curried(prevArgs: any[]) {
    return function nextCurried(...nextArgs) {
      const args = [...prevArgs, ...nextArgs];
      if (args.length >= arity) {
        return fn(...args);
      }
      return curried(args);
    };
  })([]);
}

export const identity = <T>(x: T): T => x;

export const valueOrUndefined = <T>(x: T | undefined | null): T | undefined => x ?? undefined;
export const valueOrNull = <T>(x: T | undefined | null): T | null => x ?? null;
export const removeEmptyValues = <T>(x: T | undefined | null): x is T => !!x;

export const trim = (x: string): string => x.trim();
export const pick = curry(getPropFromObj);

export const isEqualTo = curry(isEqual);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compose = (...fnx: any[]): any =>
  fnx.reduceRight((f, g) => (...args) => g(f(...args)));

export const filter = (predicate) => (reduceFn) => (acc, curr, idx) =>
  predicate(curr) ? reduceFn(acc, curr, idx) : acc;

export const map = (transformFn) => (reduceFn) => (acc, curr, idx) =>
  reduceFn(acc, transformFn(curr), idx);
