<template>
  <checkout-card :title="t('CHECKOUT_VERIFY_TITLE')" class="verify_signatory__container">
    <div v-if="validateCustomerIsPending" t-id="verify_signatory__spinner-container">
      <transition name="fade-zoom">
        <div class="verify_signatory__validate_customer_loader">
          <b2x-spinner size="large" t-id="verify_signatory__spinner" />
        </div>
      </transition>
    </div>
    <telia-form
      v-else
      t-id="verify_signatory__form"
      class="verify_signatory__form"
      @submit.prevent="handleVerifySignatory"
      novalidate
    >
      <telia-row>
        <telia-col width="12">
          <telia-text-input
            t-id="verify_signatory__form__org-no"
            type="text"
            name="org-no"
            class="verify_signatory__form__org-no-input"
            :value="contactDetails.orgNo"
            :label="t('CHECKOUT_VERIFY_ORG_NO')"
            :pattern="isValidOrganizationNumberPattern.source"
            :pattern-error-message="t('CHECKOUT_VERIFY_INVALID_ORG_NO')"
            required
            :required-error-message="t('CHECKOUT_VERIFY_FIELD_REQUIRED')"
            @input="contactDetails.orgNo = $event.target.value"
          />
        </telia-col>
      </telia-row>
      <telia-row>
        <telia-col width="12">
          <div class="verify_signatory__form__action-container-buttons">
            <telia-button
              v-if="!authorizedOrderer"
              t-id="verify_signatory__form__submit-button"
              class="verify_signatory__form__submit-button"
              variant="primary"
              type="submit"
              :disabled="verifyButtonDisabled"
              full-width
            >
              <img class="verify_signatory__form__button-icon" src="../assets/bankIdIcon.svg" />
              <div class="verify_signatory__form__button-text">
                {{ t("CHECKOUT_VERIFY_VERIFY") }}
              </div>
            </telia-button>
            <telia-button
              v-else
              t-id="verify_signatory__form__verify-button"
              class="verify_signatory__form__button"
              variant="primary"
              type="submit"
              :disabled="verifyButtonDisabled"
            >
              {{ t("CHECKOUT_VERIFY_VERIFY_ORGANISATION") }}
            </telia-button>
          </div>
        </telia-col>
      </telia-row>
      <telia-row v-if="bankIdUrlError" class="verify_signatory__form__error-container">
        <telia-col width="12">
          <telia-notification
            class="verify_signatory__form__warning-message"
            t-id="verify_signatory__form__bank-id-error-message"
            heading-tag="h3"
            variant="inline"
            status="error"
          >
            <span slot="heading">{{ t("BANK_ID_LOGIN_URL_ERROR_TITLE") }}</span>
            <telia-p slot="content">{{ t("BANK_ID_LOGIN_URL_ERROR_MESSAGE") }}</telia-p>
          </telia-notification>
        </telia-col>
      </telia-row>
      <telia-row v-if="showValidationWarnings" class="verify_signatory__form__error-container">
        <telia-col width="12">
          <telia-notification
            v-if="showMultipleSignatoryWarning"
            class="verify_signatory__form__warning-message"
            t-id="verify_signatory__form__multiple-signatory-warning-message"
            heading-tag="h3"
            variant="inline"
            status="warning"
          >
            <span slot="heading">{{ t("CHECKOUT_VERIFY_MULTIPLE_SIGNATORY_WARNING_TITLE") }}</span>
            <telia-p slot="content"
              >{{ t("CHECKOUT_VERIFY_MULTIPLE_SIGNATORY_WARNING_MESSAGE") }}
            </telia-p>
          </telia-notification>
          <telia-notification
            v-else-if="showNotVerifiedWarning"
            class="verify_signatory__form__warning-message"
            t-id="verify_signatory__form__not-verified-warning-message"
            heading-tag="h3"
            variant="inline"
            status="error"
          >
            <span slot="heading">{{ t("CHECKOUT_VERIFY_NOT_VERIFIED_ERROR_TITLE") }}</span>
            <telia-p slot="content">{{ t("CHECKOUT_VERIFY_NOT_VERIFIED_ERROR_MESSAGE") }}</telia-p>
          </telia-notification>
          <telia-notification
            v-else
            class="verify_signatory__form__warning-message"
            t-id="verify_signatory__form__contact-support-warning-message"
            heading-tag="h3"
            variant="inline"
            status="warning"
          >
            <span slot="heading">{{ t("CHECKOUT_VERIFY_CONTACT_SUPPORT_WARNING_TITLE") }}</span>
            <telia-p slot="content"
              >{{ t("CHECKOUT_VERIFY_CONTACT_SUPPORT_WARNING_MESSAGE") }}
            </telia-p>
          </telia-notification>
        </telia-col>
      </telia-row>
    </telia-form>
    <div class="verify_signatory__message-presenter">
      <message-presenter v-if="error" :messageType="error" />
    </div>
  </checkout-card>
</template>

<script setup lang="ts">
import { Ref, ref, ComputedRef, computed } from "vue";

import CheckoutCard from "./checkout-card.vue";
import { translateMixin } from "../locale";
import {
  IdentifyType,
  trackBankIdInitiate,
  trackOrganisationIdentification,
} from "../helpers/ga-helper";
import { initiateLogin } from "../services/login-service";
import { useSessionStorage } from "../composables/useSessionStorage";
import {
  AuthorizedOrderer,
  OrganisationNumber,
  OrganisationSignatoryStatus,
  SessionStorageKey,
} from "../typings/types";
import { isValidOrganizationNumberPattern } from "../utils/validation";
import MessagePresenter from "./message-presenter.vue";

interface Props {
  authorizedOrderer?: AuthorizedOrderer;
  organisationValidationStatus: OrganisationSignatoryStatus | undefined;
  validateCustomerIsPending: boolean;
  error?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "validate-customer", payload: string);
}>();

const t = translateMixin.methods.t;

const bankIdUrlError: Ref<Boolean> = ref(false);
const { setSessionStorageVariable } = useSessionStorage<OrganisationNumber>(
  SessionStorageKey.ORGANISATION_NUMBER
);

const contactDetails: Ref<OrganisationNumber> = ref({
  orgNo: "",
});

async function handleVerifySignatory() {
  setSessionStorageVariable(contactDetails.value);
  bankIdUrlError.value = false;
  if (props.authorizedOrderer) {
    trackOrganisationIdentification(IdentifyType.VERIFY_ORGANISATION);
    emit("validate-customer", contactDetails.value.orgNo);
  } else {
    try {
      const { redirectUrl } = await initiateLogin({
        targetUrl: window.location.href,
        forceMethod: "BANKID",
      });
      if (!redirectUrl) throw new Error();
      trackBankIdInitiate();
      window.location.href = redirectUrl;
    } catch {
      bankIdUrlError.value = true;
    }
  }
}

const verifyButtonDisabled: ComputedRef<Boolean> = computed(() => {
  return !isValidOrganizationNumberPattern.test(contactDetails.value.orgNo);
});

const showValidationWarnings: ComputedRef<boolean> = computed(() => {
  return (
    validationStatusIsSet.value &&
    validationStatusIsNotVerified.value &&
    validateCustomerIsNotPending.value
  );
});

const validateCustomerIsNotPending: ComputedRef<boolean> = computed(() => {
  return !props.validateCustomerIsPending;
});

const validationStatusIsNotVerified: ComputedRef<boolean> = computed(() => {
  return props.organisationValidationStatus !== OrganisationSignatoryStatus.VERIFIED;
});

const validationStatusIsSet: ComputedRef<boolean> = computed(() => {
  return !!props.organisationValidationStatus;
});

const showMultipleSignatoryWarning: ComputedRef<boolean> = computed(() => {
  return props.organisationValidationStatus === OrganisationSignatoryStatus.MULTIPLE_SIGNATORY;
});

const showNotVerifiedWarning: ComputedRef<boolean> = computed(() => {
  return props.organisationValidationStatus === OrganisationSignatoryStatus.NOT_VERIFIED;
});
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/tokens";

.verify_signatory {
  &__container {
    margin-bottom: $telia-spacing-24;
  }

  &__form {
    & .telia-form {
      padding-bottom: 0 !important;
    }

    &__grid {
      padding: 0;
    }

    &__error-container {
      margin-top: $telia-spacing-24;
    }

    &__button-icon {
      max-height: 32px;
    }

    &__warning-message {
      margin-bottom: 0 !important;
    }
  }
}
</style>
