<template>
  <div class="contact-details-skeleton" t-id="contact-details-skeleton">
    <telia-skeleton
      class="contact-details-skeleton__paragraph contact-details-skeleton__paragraph--sm"
    />
    <telia-skeleton
      class="contact-details-skeleton__paragraph contact-details-skeleton__paragraph--lg"
    />
    <telia-skeleton
      class="contact-details-skeleton__paragraph contact-details-skeleton__paragraph--md"
    />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.contact-details-skeleton {
  &__paragraph {
    height: 2.4rem;
    margin-bottom: $telia-spacing-4;

    &--sm {
      width: 40%;
    }

    &--md {
      width: 60%;
    }

    &--lg {
      width: 80%;
    }
  }
}
</style>
