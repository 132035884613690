<template>
  <div ref="root" t-id="b2b-checkout-open" class="b2b-checkout">
    <page-header :title="t('CHECKOUT_HEADER_TITLE')" :is-open-pages="true" />
    <telia-grid class="b2b-checkout-content">
      <telia-row v-if="basketIsEmpty">
        <telia-col
          class="b2b-checkout-content__card-column b2b-checkout-content__empty-basket"
          t-id="b2b-checkout-content__empty-basket"
          width="12"
        >
          <telia-heading
            t-id="b2b-checkout-content__empty-basket-title"
            tag="h3"
            variant="title-300"
          >
            {{ t("CHECKOUT_BASKET_EMPTY_TITLE") }}
          </telia-heading>
          <telia-link
            t-id="b2b-checkout-go-back"
            class="b2b-checkout-content__continue"
            variant="standalone"
            :href="goBackUrl"
          >
            {{ t("CONTINUE_BUTTON_TEXT") }}
          </telia-link>
        </telia-col>
      </telia-row>
      <telia-row
        v-if="!basketIsEmpty"
        t-id="b2b-checkout-content-row"
        class="b2b-checkout-content-row"
      >
        <telia-col width="12" width-lg="7">
          <div
            v-if="showVerifySignatory"
            class="b2b-checkout-content__card-column"
            t-id="b2b-checkout-content__not-verified"
          >
            <verify-signatory
              :authorized-orderer="loggedInUser"
              :organisation-validation-status="organisationValidationStatus"
              :validate-customer-is-pending="validateCustomerIsPending || loggedInUserPending"
              :error="loggedInUserError"
              @validate-customer="handleValidateCustomer"
            />
          </div>
          <div
            v-else
            class="b2b-checkout-content__card-column"
            t-id="b2b-checkout-content__verified"
          >
            <verified-signatory
              :name="loggedInUser?.name"
              :organization-name="organization?.name"
              :organization-number="organization?.organizationNumber"
              :has-agreement="organization?.hasAgreement"
              :show-verified-badge="true"
              @change-organisation="handleChangeOrganisation"
            />
            <contact-details
              v-if="loggedInUser"
              t-id="b2b-checkout-contact-details"
              :loading="loggedInUserPending"
              :authorized-orderer="loggedInUser"
              @update-details="handleUpdateContactDetails"
            />
            <template
              v-if="isVerified && !organization?.hasAgreement"
              t-id="b2b-checkout-content__billing-account"
            >
              <select-billing-account
                v-if="!showCreateBillingAccount"
                :basket-pending="basketPending"
                :has-billing-accounts="true"
                :has-no-billing-accounts="false"
                :has-billing-account-error="false"
                :billing-accounts="billingAccounts"
                :billing-accounts-pending="false"
                :new-billing-account-ids="newBillingAccountIds"
                :selected-billing-account-ids-map="selectedBillingAccountIdMap"
                :selected-billing-account-mode="BillingAccountMode.SINGLE"
                @show-create-billing-account="setShowCreateBillingAccount(true)"
                @select-billing-account-single="setSelectedBillingAccount"
              />
              <create-billing-account-component
                v-else
                :create-billing-account-error="createBillingAccountError"
                :create-billing-account-pending="createBillingAccountPending"
                :is-create-billing-account-disabled="!isVerified"
                :payment-due-date-days="30"
                :default-address="defaultAddress"
                :default-reference="loggedInUser?.name"
                :billing-cycles="billingCycles"
                @hide-create-billing-account="setShowCreateBillingAccount(false)"
                @submit-billing-account="createBillingAccount"
              />
            </template>
            <template v-else>
              <checkout-card-placeholder
                class="b2b-checkout-content__placeholder"
                :title="t('BILLING_ACCOUNT_PLACEHOLDER_TITLE')"
                :t-id="'billing-account-placeholder--card'"
              />
            </template>
            <div
              v-if="isVerified && !organization?.hasAgreement"
              class="b2b-checkout-content__create-myb-account"
              t-id="b2b-checkout-content__create-myb-account"
            >
              <create-myb-account @create-account="handleCreateMybAccount" />
            </div>
            <div v-else>
              <checkout-card-placeholder
                class="b2b-checkout-content__placeholder"
                :title="t('mybusinessotpcheckout.createMybAccountHeading')"
                :t-id="'create-myb-account-placeholder--card'"
              />
            </div>

            <checkout-confirmation
              class="b2b-checkout-content__confirmation-desktop"
              :is-tcad-user="false"
              :terms-and-conditions-link="basket?.generalTermsAndConditions"
              :checkout-pending="checkoutPending"
              :is-checkout-button-disabled="isCheckoutButtonDisabled"
              :checkout-error="checkoutError"
              @checkout-basket="handleCheckoutDetachedBasket"
            />
          </div>
        </telia-col>
        <telia-col width="12" width-lg="5">
          <sticky-container>
            <checkout-card :title="t('BASKET_SUMMARY_TITLE')" bottom-margin="sm" :active="false">
              <b2b-basket
                v-if="!basketPending && !basketError"
                t-id="b2b-checkout-basket"
                class="b2b-checkout-basket"
                :basket="enrichedBasket"
                scope-id=""
                :tscid.attr="tscid"
                hide-go-to-checkout
                @update-basket="handleItemDeleted"
              />
              <telia-skeleton
                v-if="basketPending && !basketError"
                t-id="b2b-checkout-content__basket-skeleton"
                class="b2b-checkout-content__basket-skeleton"
              />
              <message-presenter v-if="basketError" :messageType="basketError" />
            </checkout-card>
          </sticky-container>
        </telia-col>
        <telia-col width="12" class="b2b-checkout-content__confirmation-mobile">
          <checkout-confirmation
            :is-tcad-user="false"
            :terms-and-conditions-link="basket?.generalTermsAndConditions"
            :checkout-pending="checkoutPending"
            :is-checkout-button-disabled="isCheckoutButtonDisabled"
            :checkout-error="checkoutError"
            @checkout-basket="handleCheckoutDetachedBasket"
          />
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, Ref, ref, watch, watchEffect } from "vue";
import "@telia/b2b-basket";
import { currentLanguage } from "@telia/b2b-i18n";
import {
  B2bCheckoutDetails,
  BillingAccountMode,
  ContactDetails as ContactDetailsType,
  ContactDetailsData,
  CreateMybAccount as CreateMybAccountType,
  Lang,
  OrganisationSignatoryStatus,
  SessionStorageKey,
} from "./typings/types";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import type { CheckoutBasketItemDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import { translateMixin, translateSetup } from "./locale";
import { domEmit } from "./utils/customEmitHelper";
import { getBasketIdFromUrl } from "./utils/urlUtils";
import PageHeader from "./components/page-header.vue";
import MessagePresenter from "./components/message-presenter.vue";
import CheckoutConfirmation from "./components/checkout-confirmation.vue";
import SelectBillingAccount from "./components/billing-accounts/select-billing-account.vue";
import StickyContainer from "./components/sticky-container.vue";
import CreateBillingAccountComponent from "./components/billing-accounts/create-billing-account.vue";
import CheckoutCardPlaceholder from "./components/checkout-card-placeholder.vue";
import ContactDetails from "./components/contact-details/contact-details.vue";
import CreateMybAccount from "./components/create-myb-account.vue";
import { useGetDetachedBasket } from "./composables/useGetDetachedBasket";
import { useCheckoutDetachedBasket } from "./composables/useCheckoutDetachedBasket";
import { useOpenCreateBillingAccount } from "./composables/useCreateBillingAccount";
import { useLoggedInUser } from "./composables/useLoggedInUser";
import { useSessionStorage } from "./composables/useSessionStorage";
import { useValidateCustomer } from "./composables/useValidateCustomer";
import CheckoutCard from "./components/checkout-card.vue";
import {
  getSuccessOrFunnelFail,
  IdentifyType,
  trackOrganisationIdentification,
} from "../src/helpers/ga-helper";
import { getBillingTerms } from "./services/billing-terms-service";
import { useIsLoggedIn } from "./composables/useIsLoggedIn";
import { DetachedBasketDTO } from "@telia/b2b-rest-client/dist/corp-open-basket-management";
import VerifySignatory from "./components/verify-signatory.vue";
import VerifiedSignatory from "./components/verified-signatory.vue";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import { getOrganisation } from "./services/party-information-service";
import { getAgreementForExpressCheckout } from "./services/product-listing-service";

loadTranslations();
const t = translateMixin.methods.t;

const root = ref<HTMLElement | null>(null);

const componentRef = ref(0);

const basketId = getBasketIdFromUrl();

const goBackUrl = `/foretag/bredband/bestall`;

const { isLoggedIn } = useIsLoggedIn();

const { basket, basketError, basketPending, basketIsEmpty, defaultAddress } = useGetDetachedBasket(
  basketId,
  currentLanguage().toLocaleUpperCase() as Lang,
  getSuccessOrFunnelFail({
    isLoggedIn: isLoggedIn,
    isOpenPages: true,
    basket: ref(),
    agreementType: AgreementType.SA,
  })
);

const successOrFunnelFail = getSuccessOrFunnelFail({
  isLoggedIn: isLoggedIn,
  isOpenPages: true,
  basket: basket,
  agreementType: AgreementType.SA,
});

const { getSessionStorageVariable: getCheckoutDetailsFromSessionStorage } =
  useSessionStorage<B2bCheckoutDetails>(SessionStorageKey.B2B_CHECKOUT_DETAILS);

const { setSessionStorageVariable: setCreateMybAccountInSessionStorage } =
  useSessionStorage<CreateMybAccountType>(SessionStorageKey.CREATE_MYB_ACCOUNT);

const {
  organisationValidationStatus,
  validateCustomerIsPending,
  fetchOrganisationValidationStatus,
} = useValidateCustomer(successOrFunnelFail);

const { loggedInUser, organization, loggedInUserPending, loggedInUserError } = useLoggedInUser(
  fetchOrganisationValidationStatus
);
const tscid = computed<string | null>(() => organization.value?.tscid || null);

const showCreateBillingAccount: Ref<boolean> = ref(true);
const selectedBillingAccount: Ref<BillingAccountDTO | null> = ref(null);
const billingAccounts: Ref<BillingAccountDTO[]> = ref([]);
const billingCycles: Ref<string[]> = ref([]);
const createMybAccount: Ref<boolean> = ref(false);

const {
  pending: createBillingAccountPending,
  error: createBillingAccountError,
  newBillingAccountIds,
  createBillingAccount,
} = useOpenCreateBillingAccount(
  tscid,
  billingAccounts,
  setSelectedBillingAccount,
  setShowCreateBillingAccount,
  successOrFunnelFail
);

const {
  error: checkoutError,
  pending: checkoutPending,
  checkoutDetachedBasket,
} = useCheckoutDetachedBasket(tscid, basketId, isLoggedIn, successOrFunnelFail);

const contactDetailsValid: Ref<boolean> = ref(false);

const isVerified: ComputedRef<boolean> = computed(() => {
  return (
    !!tscid.value &&
    !validateCustomerIsPending.value &&
    organisationValidationStatus.value === OrganisationSignatoryStatus.VERIFIED
  );
});

const isCheckoutButtonDisabled: ComputedRef<boolean> = computed(() => {
  return (
    selectedBillingAccount.value === null ||
    basket.value === undefined ||
    checkoutPending.value ||
    !isVerified.value ||
    !contactDetailsValid.value
  );
});

const selectedBillingAccountIdMap: ComputedRef<Record<string, string | null>> = computed(() => {
  let productIdToBillingAccountMap: Record<string, string | null> = {};
  productIdToBillingAccountMap["dummy"] = selectedBillingAccount.value?.id || null;
  return productIdToBillingAccountMap;
});

const enrichedBasket: ComputedRef<DetachedBasketDTO | null> = computed(() => {
  const basketCopy = deepCopy(basket.value);
  if (
    basketCopy?.broadbandItems?.[0] &&
    basketCopy.broadbandItems[0].startDate != getCheckoutDetailsFromSessionStorage().startDate
  ) {
    basketCopy.broadbandItems[0].startDate = getCheckoutDetailsFromSessionStorage().startDate;
  }
  return basketCopy;
});

const showVerifySignatory: ComputedRef<boolean> = computed(() => {
  return (
    !loggedInUser.value ||
    !isVerified.value ||
    organisationValidationStatus.value !== OrganisationSignatoryStatus.VERIFIED
  );
});

watch(basket, () => {
  if (basket.value && basket.value.broadbandItems?.[0] && !hasSettingsInSessionStorage()) {
    emitGoToConfigure(basket.value.id);
  }
});

watchEffect(async () => {
  if (organisationValidationStatus.value === OrganisationSignatoryStatus.VERIFIED) {
    try {
      const { billingCycles: billingCyclesResponse } = await getBillingTerms();
      billingCycles.value = billingCyclesResponse as string[];
    } catch (e) {
      /*noop*/
    }
  }
});

function emitGoToConfirmation(orderId) {
  root.value && domEmit(root.value, "go-to-confirmation", orderId);
}

function emitGoToConfigure(configurationId) {
  root.value && domEmit(root.value, "go-to-configure", configurationId);
}

function hasSettingsInSessionStorage() {
  return getCheckoutDetailsFromSessionStorage().settings?.[0];
}

async function loadTranslations() {
  await translateSetup(["mybusinessotpcheckout"]);
  componentRef.value++;
}

function setCreateMybAccount() {
  if (createMybAccount.value && loggedInUser.value && organization.value?.organizationNumber) {
    setCreateMybAccountInSessionStorage({
      createAccount: createMybAccount.value,
      contactDetails: {
        email: loggedInUser.value?.email,
        phone: loggedInUser.value?.phoneNumber,
        organisation: { orgNo: organization.value.organizationNumber },
      } as ContactDetailsType,
    });
  } else {
    setCreateMybAccountInSessionStorage({
      createAccount: false,
      contactDetails: undefined,
    });
  }
}

function setShowCreateBillingAccount(isVisible) {
  showCreateBillingAccount.value = isVisible;
}

function setSelectedBillingAccount(billingAccount: BillingAccountDTO) {
  selectedBillingAccount.value = billingAccount;
}

async function handleChangeOrganisation() {
  trackOrganisationIdentification(IdentifyType.CHANGE_ORGANISATION);
  organisationValidationStatus.value = undefined;
}

function handleCheckoutDetachedBasket() {
  if (basket.value) {
    const { settings, startDate } = getCheckoutDetailsFromSessionStorage();
    const payload = {
      settings,
      checkoutBasketItems:
        basket.value.broadbandItems?.map(
          (item): CheckoutBasketItemDTO => ({
            basketItemId: item.id as string,
            billingAccountId: selectedBillingAccount.value?.id as string,
          })
        ) ?? [],
      email: loggedInUser.value?.email || "",
      phoneNumber: loggedInUser.value?.phoneNumber || "",
      startDate,
    };
    checkoutDetachedBasket(basket.value, payload, emitGoToConfirmation);
    setCreateMybAccount();
  }
}

function handleItemDeleted() {
  basket.value = undefined;
}

function handleCreateMybAccount(create: boolean) {
  createMybAccount.value = create;
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function handleUpdateContactDetails(event: ContactDetailsData) {
  loggedInUser.value = event.authorizedOrderer;
  contactDetailsValid.value = event.isValid;
}

async function handleValidateCustomer(organisationNumber: string) {
  const organisationResponse = await getOrganisation(organisationNumber);
  const agreement = await getAgreementForExpressCheckout(organisationResponse.tscid || "");
  if (organization.value) {
    organization.value.tscid = organisationResponse.tscid;
    organization.value.name = organisationResponse.name;
    organization.value.organizationNumber = organisationNumber;
    organization.value.hasAgreement = !!agreement?.id;
  }
  fetchOrganisationValidationStatus(organisationNumber);
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/breakpoints/mixins";
@import "@teliads/components/foundations/grid/variables";
@import "~@teliads/components/foundations/borders/variables";

.b2b-checkout {
  background-color: $telia-white;
  padding-bottom: $telia-spacing-48;
  min-height: calc(
    100vh - 607px
  ); // subtract the height of the header and footer (607px) from maximum viewport height on open pages
}

.b2b-checkout-basket {
  display: block;
}

.b2b-checkout-header {
  &__link {
    display: flex;
    align-items: center;
    margin-bottom: $telia-spacing-32;
  }
}

.b2b-checkout-content {
  margin-top: $telia-spacing-16;

  &__header-row {
    margin-bottom: $telia-spacing-64;
  }

  &__placeholder {
    margin-bottom: $telia-spacing-24;
  }

  &__empty-basket {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__continue {
    margin-top: $telia-spacing-24;
  }

  &__basket {
    padding: 0 $telia-spacing-32;

    &-header {
      margin-bottom: $telia-spacing-24;
    }

    &-skeleton {
      height: 20rem;
      width: 100%;
      margin-bottom: $telia-spacing-24;
    }
  }

  &__create-myb-account {
    margin-top: $telia-spacing-24;
    margin-bottom: $telia-spacing-24;
  }

  &__confirmation {
    &-desktop {
      margin-top: $telia-spacing-24;
      display: none;

      @media screen and (min-width: $telia-breakpoint-large) {
        display: block;
      }
    }

    &-mobile {
      margin-top: $telia-spacing-24;

      @media screen and (min-width: $telia-breakpoint-large) {
        display: none;
      }
    }
  }
}
</style>
