import {
  BroadbandItemDTO,
  CheckoutBasketItemDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";

export type BasketItemId = string;
export type BillingAccountId = string;

export class BillingAccountManager {
  public productIdToBillingAccountMap: Record<BasketItemId, BillingAccountId | null>;

  constructor(basketItems: BroadbandItemDTO[] = []) {
    this.productIdToBillingAccountMap = this.createMapFromBasketItems(basketItems);
  }

  public resetSelectedBillingAccounts(): void {
    Object.keys(this.productIdToBillingAccountMap).forEach((key: string) => {
      this.productIdToBillingAccountMap[key] = null;
    });
  }

  public get getProductIdToBillingAccountMap(): Record<BasketItemId, BillingAccountId | null> {
    return this.productIdToBillingAccountMap;
  }

  public allBasketItemsHaveBillingAccount(): boolean {
    return Object.values(this.productIdToBillingAccountMap).every(
      (value: string | null) => value !== null
    );
  }

  public setBillingAccountForAllBasketItems(billingAccountId: string): void {
    Object.keys(this.productIdToBillingAccountMap).forEach((key: string) => {
      this.productIdToBillingAccountMap[key] = billingAccountId;
    });
  }

  public setBillingAccountForBasketItem(
    basketItemId: BasketItemId,
    billingAccountId: BillingAccountId
  ): void {
    this.productIdToBillingAccountMap[basketItemId] = billingAccountId;
  }

  public getAsCheckoutPayload(): CheckoutBasketItemDTO[] {
    return Object.entries(this.productIdToBillingAccountMap).map(
      ([basketItemId, billingAccountId]: [BasketItemId, BillingAccountId | null]) => ({
        basketItemId,
        billingAccountId: billingAccountId as BillingAccountId,
      })
    );
  }

  public updateFromBasket(basketItems: BroadbandItemDTO[]) {
    const mapEntries = Object.entries(this.productIdToBillingAccountMap);
    if (mapEntries.length > basketItems.length) {
      this.productIdToBillingAccountMap = mapEntries.reduce((acc, curr) => {
        if (basketItems.some((x) => x.id === curr[0])) {
          acc[curr[0]] = curr[1];
        }
        return acc;
      }, {});
    } else {
      this.productIdToBillingAccountMap = this.createMapFromBasketItems(basketItems);
    }
  }

  public createMapFromBasketItems(
    basketItems: BroadbandItemDTO[]
  ): Record<BasketItemId, BillingAccountId | null> {
    return basketItems.reduce(
      (map: Record<BasketItemId, BillingAccountId | null>, basketItem: BroadbandItemDTO) => {
        map[basketItem.id as BasketItemId] = null;
        return map;
      },
      {}
    );
  }
}
