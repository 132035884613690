import type {
  ContactInformationDTO,
  IndividualResponseDTO,
  RoleDTO,
} from "@telia/b2b-rest-client/dist/corp-party-information";
import { isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { TeliaRoleTypes } from "./constants";
import { removeEmptyValues, trim } from "./fp";
import { AuthorizedOrderer } from "../typings/types";

export class AuthorizedOrdererError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthorizedOrdererError";
  }
}
export async function isTcadUser(): Promise<boolean> {
  try {
    return await isTeliaAdmin();
  } catch {
    return false;
  }
}

export function toAuthorizedOrderer(
  individual: IndividualResponseDTO,
  tscid: string
): AuthorizedOrderer {
  const { contactInformation } = extractAuthorizedOrdererRole(individual, tscid);
  return {
    name: getIndividualName(individual.name),
    email: getEmailFromContactInformation(contactInformation),
    phoneNumber: getPhoneNumberFromContactInformation(contactInformation),
    tscid,
  };
}

function getIndividualName(name: IndividualResponseDTO["name"]): string {
  return [name?.firstName, name?.lastName].filter(removeEmptyValues).map(trim).join(" ");
}

function extractAuthorizedOrdererRole(individual: IndividualResponseDTO, tscid: string): RoleDTO {
  const authorizedOrderer = individual.roles?.find(isAuthorizedOrdererForTscid(tscid));
  if (!authorizedOrderer) throw new AuthorizedOrdererError("No authorizedOrderer found");
  return authorizedOrderer;
}

function isAuthorizedOrdererForTscid(tscid: string): (role: RoleDTO) => boolean {
  return function _isAuthorizedOrderer(role: RoleDTO): boolean {
    return role.roleType === TeliaRoleTypes.AUTHORIZED_ORDERER && role.tscid === tscid;
  };
}

function getEmailFromContactInformation(
  contactInformation: ContactInformationDTO[] = []
): string | undefined {
  return contactInformation.find((d) => d.type === "EMAIL")?.value;
}

function getPhoneNumberFromContactInformation(
  contactInformation: ContactInformationDTO[] = []
): string | undefined {
  return contactInformation.find((d) => d.type === "PHONE_NUMBER")?.value;
}
