<template>
  <div class="billing-accounts-skeleton">
    <telia-row class="billing-accounts-skeleton__mode-button-container">
      <telia-col width="12" width-md="6">
        <telia-skeleton class="billing-accounts-skeleton__mode-button"></telia-skeleton>
      </telia-col>
      <telia-col width="12" width-md="6">
        <telia-skeleton class="billing-accounts-skeleton__mode-button"></telia-skeleton>
      </telia-col>
    </telia-row>
    <div class="billing-accounts-skeleton__sub-header-container">
      <telia-skeleton class="billing-accounts-skeleton__sub-header"></telia-skeleton>
      <telia-skeleton
        class="billing-accounts-skeleton__create-billing-account-button"
      ></telia-skeleton>
    </div>
    <telia-skeleton class="billing-accounts-skeleton__searchInput"></telia-skeleton>
    <div class="billing-accounts-skeleton__list">
      <telia-skeleton class="billing-accounts-skeleton__listItem"></telia-skeleton>
    </div>
    <div class="billing-accounts-skeleton__list">
      <telia-skeleton class="billing-accounts-skeleton__listItem"></telia-skeleton>
    </div>
    <div class="billing-accounts-skeleton__list">
      <telia-skeleton class="billing-accounts-skeleton__listItem"></telia-skeleton>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/borders/variables";

.billing-accounts-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: $telia-spacing-64;

  &__searchInput {
    height: $telia-spacing-48;
    margin-bottom: $telia-spacing-16;
  }

  &__listItem {
    height: 5.4rem;
  }

  &__list + &__list {
    margin-top: $telia-spacing-16;
  }

  &__list {
    border-radius: $telia-spacing-16;
    overflow: hidden;
  }

  &__mode-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $telia-spacing-48 !important;
  }

  &__mode-button {
    width: 100%;
    height: 5rem;
    border-radius: $telia-border-radius-8;
    overflow: hidden;
  }

  &__sub-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $telia-spacing-24;
  }

  &__sub-header {
    max-width: 14rem;
    width: 100%;
    height: 3rem;
  }

  &__create-billing-account-button {
    max-width: 19rem;
    width: 100%;
    height: 4rem;
    border-radius: $telia-border-radius-full;
    overflow: hidden;
  }
}
</style>
