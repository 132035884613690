import { ref, Ref } from "vue";
import { isTcadUser as fetchIsTcadUser } from "../utils/user-utils";

export function useTcadUser() {
  const isTcadUser: Ref<boolean> = ref(false);
  (async () => {
    isTcadUser.value = await fetchIsTcadUser();
  })();
  return {
    isTcadUser,
  };
}
