<template>
  <div t-id="multiple-billing-accounts">
    <ul v-if="basketItems">
      <li v-for="basketItem in basketItems" :key="basketItem.id">
        <multiple-billing-account-detail
          t-id="multiple-billing-account-detail"
          :basket-item="basketItem"
          :billing-accounts="billingAccounts"
          :selected-billing-account-id="
            valueOrUndefined(selectedBillingAccountIdsMap[basketItem.id])
          "
          @select-billing-account="emit('select-billing-account', $event)"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { BroadbandItemDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import MultipleBillingAccountDetail from "./multiple-billing-account-detail.vue";
import type { BasketItemId, BillingAccountId } from "../../utils/BillingAccountManager";
import { valueOrUndefined } from "../../utils/fp";

interface Props {
  basketItems?: BroadbandItemDTO[];
  billingAccounts: BillingAccountDTO[];
  selectedBillingAccountIdsMap: Record<BasketItemId, BillingAccountId | null>;
}

defineProps<Props>();
const emit = defineEmits<{
  (e: "select-billing-account", payload: [BillingAccountDTO, BroadbandItemDTO]): void;
}>();
</script>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/colors/variables";

ul {
  list-style-type: none;
  margin: $telia-spacing-32 0 $telia-spacing-16;
  padding: 0;
}

li {
  padding: $telia-spacing-32 0;
  border-top: $telia-border-width-1 solid $telia-gray-300;

  &:last-of-type {
    padding: $telia-spacing-32 0 0;
  }
}
</style>
