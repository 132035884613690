<template>
  <div>
    <checkout-card
      bottom-margin="md"
      :title="t('CHECKOUT_BILLING_TITLE')"
      v-if="!multipleBillingAccountEnabled"
    >
      <template #checkout-card-heading-button-slot>
        <telia-button
          t-id="select-billing-account__create-account-button"
          class="select-billing-account__create-account-button"
          size="sm"
          variant="text"
          @click="emit('show-create-billing-account')"
          >{{ t("BILLING_ACCOUNTS_CREATE_NEW_BUTTON_TEXT") }}
        </telia-button>
      </template>
      <template #default>
        <div v-if="!basketPending">
          <single-billing-accounts
            v-if="hasBillingAccounts"
            :billing-accounts="billingAccounts"
            :new-billing-account-ids="newBillingAccountIds"
            :selected-billing-account-id="singleSelectedBillingAccountId"
            @select-billing-account="handleSelectSingleBillingAccount"
          />
          <no-billing-accounts v-if="hasNoBillingAccounts" />
          <telia-notification
            v-if="hasBillingAccountError"
            t-id="select-billing-account__billing-account-error-message"
            class="select-billing-account__billing-account-error-message"
            heading-tag="h4"
            variant="inline"
            status="warning"
          >
            <span slot="heading">{{ t("BILLING_ACCOUNTS_ERROR_TITLE") }}</span>
            <telia-p slot="content">{{ t("BILLING_ACCOUNTS_ERROR_MESSAGE") }}</telia-p>
          </telia-notification>
        </div>
        <billing-account-skeleton
          v-if="billingAccountsPending || basketPending"
          t-id="select-billing-account__billing-account-skeleton"
        />
      </template>
    </checkout-card>

    <checkout-card
      :title="t('CHECKOUT_MULTIPLE_BILLING_TITLE')"
      v-if="multipleBillingAccountEnabled"
      bottom-margin="md"
    >
      <template #default>
        <div v-if="!billingAccountsPending && !basketPending">
          <div class="select-billing-account__mode-container">
            <telia-row>
              <telia-col width="12" width-md="6">
                <b2x-radio-card
                  t-id="select-billing-account__single-billing-account-mode-radio"
                  name="billing-account-mode"
                  :value="BillingAccountMode.SINGLE"
                  :label="t('BILLING_ACCOUNT_MODE_SINGLE')"
                  :checked="selectedBillingAccountMode === BillingAccountMode.SINGLE"
                  @change="changeBillingAccountMode"
                />
              </telia-col>
              <telia-col width="12" width-md="6">
                <b2x-radio-card
                  t-id="select-billing-account__multiple-billing-account-mode-radio"
                  name="billing-account-mode"
                  :value="BillingAccountMode.MULTIPLE"
                  :label="t('BILLING_ACCOUNT_MODE_MULTIPLE')"
                  :checked="selectedBillingAccountMode === BillingAccountMode.MULTIPLE"
                  :disabled="!hasMultipleProducts || basketPending || billingAccountsPending"
                  @change="changeBillingAccountMode"
                />
              </telia-col>
            </telia-row>
          </div>
          <div class="select-billing-account__sub-header-container">
            <telia-heading tag="h4" variant="title-200"
              >{{
                selectedBillingAccountMode === BillingAccountMode.SINGLE
                  ? t("BILLING_ACCOUNT_SINGLE_MODE_SUBHEADER")
                  : t("BILLING_ACCOUNT_MULTIPLE_MODE_SUBHEADER")
              }}
            </telia-heading>
            <telia-button
              t-id="select-billing-account__create-account-button"
              class="select-billing-account__create-account-button"
              size="sm"
              variant="secondary"
              @click="emit('show-create-billing-account')"
            >
              <telia-icon slot="left" name="plus" size="sm" />
              {{ t("BILLING_ACCOUNTS_CREATE_NEW_BUTTON_TEXT") }}
            </telia-button>
          </div>
        </div>
        <div v-if="!basketPending">
          <single-billing-accounts
            v-if="hasBillingAccounts && selectedBillingAccountMode === BillingAccountMode.SINGLE"
            :billing-accounts="billingAccounts"
            :new-billing-account-ids="newBillingAccountIds"
            :selected-billing-account-id="singleSelectedBillingAccountId"
            @select-billing-account="handleSelectSingleBillingAccount"
          />
          <multiple-billing-accounts
            v-if="hasBillingAccounts && selectedBillingAccountMode === BillingAccountMode.MULTIPLE"
            :basket-items="basket?.broadbandItems"
            :billing-accounts="billingAccounts"
            :selected-billing-account-ids-map="selectedBillingAccountIdsMap"
            @select-billing-account="handleSelectMultipleBillingAccounts"
          ></multiple-billing-accounts>
          <no-billing-accounts v-if="hasNoBillingAccounts" />
          <telia-notification
            v-if="hasBillingAccountError"
            t-id="select-billing-account__billing-account-error-message"
            class="select-billing-account__billing-account-error-message"
            heading-tag="h4"
            variant="inline"
            status="warning"
          >
            <span slot="heading">{{ t("BILLING_ACCOUNTS_ERROR_TITLE") }}</span>
            <telia-p slot="content">{{ t("BILLING_ACCOUNTS_ERROR_MESSAGE") }}</telia-p>
          </telia-notification>
        </div>
        <billing-account-skeleton
          v-if="billingAccountsPending || basketPending"
          t-id="select-billing-account__billing-account-skeleton"
        />
      </template>
    </checkout-card>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, Ref } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import CheckoutCard from "../checkout-card.vue";
import SingleBillingAccounts from "./single-billing-account.vue";
import NoBillingAccounts from "./no-billing-accounts.vue";
import BillingAccountSkeleton from "./billing-account-skeleton.vue";
import MultipleBillingAccounts from "./multiple-billing-accounts.vue";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { FeatureFlags } from "../../feature-flags/featureFlags";
import "@telia/b2x-radio-card";
import { BillingAccountMode } from "../../typings/types";
import type {
  BasketDTO,
  BroadbandItemDTO,
} from "@telia/b2b-rest-client/dist/corp-basket-management";
import type { BasketItemId, BillingAccountId } from "../../utils/BillingAccountManager";
import { valueOrUndefined } from "../../utils/fp";
import { useFlag } from "@unleash/proxy-client-vue";

interface Props {
  billingAccounts: BillingAccountDTO[];
  billingAccountsPending: boolean;
  hasBillingAccounts: boolean;
  hasNoBillingAccounts: boolean;
  hasBillingAccountError: boolean;
  newBillingAccountIds: string[];
  selectedBillingAccountIdsMap: Record<BasketItemId, BillingAccountId | null>;
  basket?: BasketDTO;
  basketPending: boolean;
  selectedBillingAccountMode: BillingAccountMode;
}

translateSetup();

const t = translateMixin.methods.t;

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "show-create-billing-account"): void;
  (e: "select-billing-account-single", billingAccount: BillingAccountDTO): void;
  (e: "select-billing-account-multiple", payload: [BillingAccountDTO, BroadbandItemDTO]): void;
  (e: "change-billing-account-mode", mode: BillingAccountMode): void;
}>();
const multipleBillingAccountEnabled: Ref<boolean> = useFlag(FeatureFlags.MULTICHECKOUT);
const hasMultipleProducts: ComputedRef<boolean> = computed(() => {
  return (props.basket?.broadbandItems?.length ?? 0) > 1;
});

const singleSelectedBillingAccountId: ComputedRef<string | undefined> = computed(() => {
  const firstValue = (Object.values(props.selectedBillingAccountIdsMap) as (string | null)[])?.[0];
  return valueOrUndefined<string>(firstValue);
});

function handleSelectSingleBillingAccount(selectedBillingAccount: BillingAccountDTO): void {
  emit("select-billing-account-single", selectedBillingAccount);
}

function handleSelectMultipleBillingAccounts(payload: [BillingAccountDTO, BroadbandItemDTO]): void {
  emit("select-billing-account-multiple", payload);
}

function changeBillingAccountMode(event: InputEvent): void {
  const value = (event.target as HTMLInputElement).value as BillingAccountMode;
  emit("change-billing-account-mode", value);
}
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/variables";

.select-billing-account {
  &__create-account-button {
    > button {
      padding: 0;
      margin-left: $telia-spacing-4;
    }
  }

  &__mode-container {
    margin: $telia-spacing-32 0 $telia-spacing-48;

    .radio-card__heading {
      margin-bottom: 0;
    }
  }

  &__sub-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $telia-spacing-24;
  }

  &__billing-account-error-message {
    display: block;
    margin-bottom: $telia-spacing-16;
  }
}
</style>
