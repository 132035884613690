export function formatPhoneNumber(phoneNumber: string | undefined): string | undefined {
  if (!phoneNumber) return;

  phoneNumber = phoneNumber.replace(/[-, " "]/g, '');

  if (phoneNumber.startsWith("+46")) {
    return phoneNumber.replace(/^\+46(\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
  }

  if (phoneNumber.startsWith("0046")) {
    return phoneNumber?.replace(/^0046(\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
  }

  return phoneNumber.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, "$1-$2 $3 $4");
}

export function formatOrganizationNumber(
  organizationNumber: string | undefined
): string | undefined {
  return organizationNumber?.replace(/(\d+)(\d{4})/, "$1-$2");
}
