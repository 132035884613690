import { ref, Ref } from "vue";
import type { AgreementBillingTermsDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { getBillingTermsFromAgreement } from "../services/agreement-billing-terms";

interface AgreementBillingTermsComposable {
  paymentDueDateDays: Ref<number | undefined>;
  hasLetterInvoiceFee: Ref<boolean>;
  billingCycles: Ref<string[]>;
}

export function useAgreementBillingTerms(
  scopeId: string,
  tscid: string
): AgreementBillingTermsComposable {
  const paymentDueDateDays: Ref<number | undefined> = ref();
  const hasLetterInvoiceFee: Ref<boolean> = ref(true);
  const billingCycles: Ref<string[]> = ref([]);
  (async () => {
    try {
      const agreementBillingTerms: Required<AgreementBillingTermsDTO> = (await getBillingTermsFromAgreement(
        scopeId,
        tscid
      )) as Required<AgreementBillingTermsDTO>;
      paymentDueDateDays.value = agreementBillingTerms.paymentDueDateDays;
      hasLetterInvoiceFee.value = agreementBillingTerms.hasLetterInvoiceFee;
      billingCycles.value = agreementBillingTerms.billingCycles;
    } catch (e) {
      /*noop*/
    }
  })();
  return {
    paymentDueDateDays,
    hasLetterInvoiceFee,
    billingCycles,
  };
}
