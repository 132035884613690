<template>
  <checkout-card
    :title="t('CHECKOUT_CONTACT_TITLE')"
    class="contact-details__container"
    bottom-margin="lg"
    :active="false"
  >
    <contact-details-skeleton v-if="loading" />
    <template v-else>
      <div class="contact-details__field-container">
        <telia-text-input
          class="contact-details__email-input"
          t-id="contact-details__email-input"
          type="email"
          autocomplete="email"
          :pattern="validationPatterns.email.source"
          :pattern-error-message="t('CONTACT_DETAILS_INVALID_EMAIL')"
          required
          :required-error-message="t('CONTACT_DETAILS_FIELD_REQUIRED')"
          :label="t('CONTACT_DETAILS_EMAIL')"
          :value="authorizedOrderer.email"
          :disabled="emailAlreadyProvided"
          @input="handleUpdatedEmail"
        />
      </div>
      <div class="contact-details__field-container">
        <telia-text-input
          class="contact-details__phone-number-input"
          t-id="contact-details__phone-number-input"
          type="tel"
          name="phone"
          :pattern="validationPatterns.phone.source"
          :pattern-error-message="t('CONTACT_DETAILS_INVALID_MOBILE_NUMBER')"
          required
          :required-error-message="t('CONTACT_DETAILS_FIELD_REQUIRED')"
          :label="t('CONTACT_DETAILS_MOBILE_NUMBER')"
          :value="authorizedOrderer.phoneNumber"
          :disabled="phoneAlreadyProvided"
          @input="handleUpdatedPhoneNumber"
        />
      </div>
    </template>
  </checkout-card>
</template>

<script setup lang="ts">
import { ComputedRef, Ref, ref, computed, onMounted } from "vue";
import CheckoutCard from "../checkout-card.vue";
import { translateMixin, translateSetup } from "../../locale";
import type { AuthorizedOrderer, ContactDetailsData } from "../../typings/types";
import ContactDetailsSkeleton from "./contact-details-skeleton.vue";
import { isValidEmailPattern, isValidMobileNumberPattern } from "../../utils/validation";

interface Props {
  loading: boolean;
  contactDetailsError?: boolean;
  authorizedOrderer: AuthorizedOrderer;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update-details", value: ContactDetailsData);
}>();
const validationPatterns = ref({
  email: isValidEmailPattern,
  phone: isValidMobileNumberPattern,
});

onMounted(() => {
  if (emailAlreadyProvided && phoneAlreadyProvided) {
    emit("update-details", {
      isValid: isValid.value,
      authorizedOrderer: {
        ...props.authorizedOrderer,
        phoneNumber: props.authorizedOrderer.phoneNumber,
        email: props.authorizedOrderer.email,
      },
    });
  }
});

function handleUpdatedPhoneNumber(event) {
  emit("update-details", {
    isValid: isValid.value,
    authorizedOrderer: { ...props.authorizedOrderer, phoneNumber: event.target.value },
  });
}

function handleUpdatedEmail(event) {
  emit("update-details", {
    isValid: isValid.value,
    authorizedOrderer: { ...props.authorizedOrderer, email: event.target.value },
  });
}

const isValid: ComputedRef<boolean> = computed(() => {
  return (
    validationPatterns.value.phone.test(props.authorizedOrderer.phoneNumber || "") &&
    validationPatterns.value.email.test(props.authorizedOrderer.email || "")
  );
});

const emailAlreadyProvided = !!props.authorizedOrderer.email;
const phoneAlreadyProvided = !!props.authorizedOrderer.phoneNumber;

translateSetup();
const t = translateMixin.methods.t;
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/typography/variables";

.contact-details {
  &__container {
    margin-bottom: $telia-spacing-24;
  }

  &__message-presenter {
    margin-top: $telia-spacing-12;
  }

  &__field-container + &__field-container {
    margin-top: $telia-spacing-32;
  }
}
</style>
