import { compose, resolve, curry, filter, pick, isEqualTo } from "./fp";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";

const searchableFields: string[] = [
  "id",
  "reference",
  "recipient.name",
  "recipient.emailAddress",
  "recipient.phoneNumber",
  "recipient.address.city",
  "recipient.address.postalCode",
  "recipient.address.street",
  "recipient.address.additionalAddressInformation",
];

export function getBillingAccountFromId(
  billingAccounts: BillingAccountDTO[],
  id: string | undefined
): BillingAccountDTO | undefined {
  const billingAccountWithProvidedId = compose(isEqualTo(id), pick("id"));
  return billingAccounts.find(billingAccountWithProvidedId);
}

export function propIncludesSearchTerm(
  searchTerm: string,
  prop: string | undefined | null
): boolean {
  return searchTerm === "" || !!prop?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
}

export function concat(
  selectedBillingAccount: BillingAccountDTO | undefined,
  filteredBillingAccounts: BillingAccountDTO[],
  billingAccount: BillingAccountDTO
): BillingAccountDTO[] {
  if (selectedBillingAccount?.id === billingAccount.id) {
    filteredBillingAccounts.unshift(billingAccount);
  } else {
    filteredBillingAccounts.push(billingAccount);
  }
  return filteredBillingAccounts;
}

export function filterFn(searchTerm: string, billingAccount: BillingAccountDTO): boolean {
  const getPropValue = curry(resolve)(billingAccount);
  const includesSearchTerm = curry(propIncludesSearchTerm)(searchTerm);
  const propContainsSearchTerm = compose(includesSearchTerm, getPropValue);
  return searchableFields.some(propContainsSearchTerm);
}

export function filterBillingAccounts(
  billingAccounts: BillingAccountDTO[],
  selectedBillingAccount: BillingAccountDTO | undefined,
  searchTerm: string
): BillingAccountDTO[] {
  const curriedFilterFn = curry(filterFn)(searchTerm);
  const curriedConcat = curry(concat)(selectedBillingAccount);
  const billingAccountFilterReducer = filter(curriedFilterFn)(curriedConcat);
  return billingAccounts.reduce(billingAccountFilterReducer, []);
}
