import { Ref, ref } from "vue";
import type { GetOrganizationResponse } from "@telia/b2b-rest-client/dist/corp-scope-information";
import { getOrganizations } from "@telia/b2b-logged-in-service";

interface OrganisationComposable {
  organization: Ref<GetOrganizationResponse | null>;
}

export function useOrganisation(tscid: string): OrganisationComposable {
  const organization: Ref<GetOrganizationResponse | null> = ref(null);
  (async () => {
    try {
      const organizations = await getOrganizations();
      organization.value = organizations.find(organizationWithTscid(tscid)) ?? null;
    } catch {
      /* noop */
    }
  })();
  return {
    organization,
  };
}

export function organizationWithTscid(
  tscid: string
): (organization: GetOrganizationResponse) => boolean {
  return function _findOrganisation(organization: GetOrganizationResponse): boolean {
    return tscid === organization.tscid;
  };
}
