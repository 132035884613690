<script setup lang="ts">
import { translateMixin, translateSetup } from "../locale";
import { getNavigationFunction } from "../utils/urlUtils";

interface Props {
  title: string;
  linkHref?: string;
  isOpenPages: boolean;
}

const props = defineProps<Props>();
translateSetup();

const t = translateMixin.methods.t;

function handleLinkNavigation() {
  const navigationFunction = getNavigationFunction(() => props.isOpenPages);
  navigationFunction(props.linkHref as string);
}
</script>

<template>
  <header t-id="page-header" class="page-header">
    <telia-grid>
      <telia-row>
        <telia-col width="12">
          <div class="page-header__container">
            <div>
              <telia-link
                v-if="linkHref"
                t-id="b2b-checkout-go-back"
                class="page-header__link"
                :href="linkHref"
                variant="navigation"
                @click.prevent="handleLinkNavigation"
              >
                <telia-icon class="page-header__icon" slot="left" size="sm" name="arrow-left" />
                <span class="page-header__link-text">{{ t("GO_BACK_LINK") }}</span>
              </telia-link>
            </div>
            <telia-heading
              t-id="b2b-checkout-header-title"
              class="page-header__title"
              tag="h1"
              variant="display-25"
              >{{ title }}
            </telia-heading>
          </div>
        </telia-col>
      </telia-row>
    </telia-grid>
  </header>
</template>

<style lang="scss">
@import "@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/spacing/variables";

.page-header {
  background: $telia-purple-900;
  margin-bottom: $telia-spacing-96;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 24rem;
    padding: $telia-spacing-32 0;
  }

  &__title {
    color: $telia-purple-100;
  }

  &__link-text {
    color: $telia-purple-300;
    margin-left: $telia-spacing-4;
  }

  &__icon {
    color: $telia-purple-300;
  }
}
</style>
