<template>
  <div class="no-billing-accounts">
    <telia-p t-id="no-billing-accounts__text" variant="paragraph-100">{{
      t("BILLING_ACCOUNTS_NO_ACCOUNTS_AVAILABLE")
    }}</telia-p>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../../locale";
translateSetup();
const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped></style>
