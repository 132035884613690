import { SessionStorageKey } from "../typings/types";

export function useSessionStorage<T>(key: SessionStorageKey) {
  function getSessionStorageVariable(): T {
    return JSON.parse(window.sessionStorage.getItem(key) ?? "{}");
  }

  function setSessionStorageVariable(value: T) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  function clearSessionStorageVariable() {
    window.sessionStorage.removeItem(key);
  }

  return { getSessionStorageVariable, setSessionStorageVariable, clearSessionStorageVariable };
}
