import { curry, map, filter, compose, identity, resolve, removeEmptyValues } from "./fp";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";

const informationFields: string[] = [
  "recipient.address.additionalAddressInformation",
  "recipient.address.street",
  "recipient.address.postalCode",
  "recipient.address.city",
  "recipient.emailAddress",
];

function indexIsPostalCodeOrCity(idx: number) {
  return [2, 3].includes(idx);
}

function uglyConcat(acc: string[], curr: string, idx: number): string[] {
  if (indexIsPostalCodeOrCity(idx)) {
    // Add postalCode and city to the street field
    acc[1] += ` ${curr}`;
    acc[1] = acc[1].replace(/\s\s+/g, " ").trim();
  } else {
    acc[idx] += curr;
  }
  return acc;
}

export function getBillingAccountInformation(billingAccount: BillingAccountDTO): string[] {
  const curriedResolve = curry(resolve)(billingAccount);
  const toPropValue = map(curriedResolve);
  const removeEmptyValues = filter(identity);
  const reducerFn = compose(toPropValue, removeEmptyValues)(uglyConcat);
  return informationFields
    .reduce(reducerFn, new Array(informationFields.length).fill(""))
    .filter(identity);
}

const contactFields: string[] = ["recipient.phoneNumber", "recipient.emailAddress"];
const addressFields: string[] = ["recipient.address.postalCode", "recipient.address.city"];

export function getBillingAccountAddressFields(billingAccount: BillingAccountDTO) {
  return [
    resolve(billingAccount, "recipient.address.additionalAddressInformation"),
    resolve(billingAccount, "recipient.address.street"),
    addressFields
      .map((addressField) => {
        return resolve(billingAccount, addressField);
      })
      .filter(removeEmptyValues)
      .join(" "),
  ].filter(removeEmptyValues);
}

export function getBillingAccountContactFields(billingAccount: BillingAccountDTO) {
  return contactFields
    .map((contactField) => {
      return resolve(billingAccount, contactField);
    })
    .filter(removeEmptyValues);
}
