import { match } from "path-to-regexp";
import { navigateToUrl } from "single-spa";

interface Match {
  params: Params;
}

interface Params {
  tscid: string;
  scopeId: string;
  basketId: string;
}

export function getParamsFromUrl(url = window.location.pathname) {
  const { params } = match("/foretag/mybusiness/:scopeId/bestall/:tscid/kassa/:basketId")(
    url
  ) as Match;
  return params;
}

export function getBasketIdFromUrl(url = window.location.pathname) {
  const { params } = match("/foretag/bredband/kassa/:basketId")(url) as Match;
  return params.basketId;
}

export function getNavigationFunction(predicateFn: () => boolean) {
  return predicateFn() ? navigateByLocationHref : navigateToUrl;
}

function navigateByLocationHref(url: string) {
  window.location.href = url;
}
