import { ref, Ref, computed, ComputedRef } from "vue";
import type { DefaultAddress, Lang } from "../typings/types";
import { getDetachedBasketById } from "../services/open-basket-management-service";
import { DetachedBasketDTO } from "@telia/b2b-rest-client/dist/corp-open-basket-management";
import { getAddressFromPointId } from "../services/address-lookup";
import { toDefaultAddress } from "../utils/address-mapper";

const Errors = {
  BasketError: "basketError",
} as const;

type ErrorKeys = typeof Errors;
type ErrorValues = (typeof Errors)[keyof ErrorKeys];

interface GetBasketComposable {
  basketPending: Ref<boolean>;
  basketError: Ref<ErrorValues | null>;
  basket: Ref<DetachedBasketDTO | undefined>;
  basketIsEmpty: ComputedRef<boolean>;
  defaultAddress: Ref<DefaultAddress | undefined>;
}

export function useGetDetachedBasket(
  basketId: string,
  language: Lang,
  successOrFunnelFail: <T>(restCall: () => Promise<T>, errorMessage: string) => Promise<T>
): GetBasketComposable {
  const basketPending: Ref<boolean> = ref(true);
  const basketError: Ref<ErrorValues | null> = ref(null);
  const basket: Ref<DetachedBasketDTO | undefined> = ref();
  const defaultAddress: Ref<DefaultAddress | undefined> = ref();

  const basketIsEmpty: ComputedRef<boolean> = computed(() => {
    return (
      !basketPending.value &&
      !basketError.value &&
      (basket.value?.broadbandItems?.length ?? 0) === 0
    );
  });

  if (!basketId) {
    return {
      basketPending,
      basketError,
      basket,
      basketIsEmpty,
      defaultAddress,
    };
  }

  (async () => {
    try {
      const response = await successOrFunnelFail(
        () => getDetachedBasketById(basketId, language),
        "Could not get basket"
      );
      if (response) {
        basket.value = response;

        const items = basket?.value?.broadbandItems;
        if (items !== undefined && items[0]?.installationAddress?.pointId) {
          defaultAddress.value = toDefaultAddress(
            await getAddressFromPointId(items[0]?.installationAddress?.pointId)
          );
        }
      }
    } catch {
      basketError.value = "basketError";
    } finally {
      basketPending.value = false;
    }
  })();

  return {
    basketPending,
    basketError,
    basket,
    basketIsEmpty,
    defaultAddress,
  };
}
