<template>
  <fieldset class="billing-cycle__fieldset" :key="componentRef">
    <legend class="sr-only">{{ t("BILLING_CYCLE_SELECTION_TITLE") }}</legend>
    <telia-heading
      class="billing-cycle__header"
      t-id="create-billing-account__billing-cycle-header"
      tag="h4"
    >
      {{ t("BILLING_CYCLE_SELECTION_TITLE") }}
    </telia-heading>
    <div class="billing-cycle">
      <div
        v-for="billingCycle in billingCycles"
        :t-id="`billing-cycle__option-${billingCycle}`"
        class="billing-cycle__option"
        :key="billingCycle"
      >
        <b2x-radio-card
          :t-id="`billing-cycle__option-card-${billingCycle}`"
          name="billingCycle"
          :id="billingCycle"
          :label="setBillingCycleLabel(billingCycle)"
          :checked="billingCycle === selectedBillingCycle"
          @change.prevent.stop="handleChange(billingCycle)"
        ></b2x-radio-card>
      </div>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { ref } from "vue";
import "@telia/b2x-radio-card";
import { translateMixin, translateSetup } from "../../locale";

const emit = defineEmits<{
  (e: "change-billing-cycle", billingCycle: string): void;
}>();

const componentRef = ref(0);

loadTranslations();
const t = translateMixin.methods.t;

async function loadTranslations() {
  await translateSetup(["mybusinessotpcheckout"]);
  componentRef.value++;
}

interface Props {
  billingCycles: string[];
  selectedBillingCycle?: string;
}

function handleChange(data: string) {
  emit("change-billing-cycle", data);
}

function setBillingCycleLabel(billingCycle) {
  const billingCycleLabel = t("mybusinessotpcheckout.billingCycle." + billingCycle.toLowerCase());
  return billingCycleLabel ? billingCycleLabel : billingCycle;
}

defineProps<Props>();
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/tokens";

.billing-cycle {
  &__fieldset {
    border: 0;
    padding: 0;
    margin-bottom: $telia-spacing-16;
  }

  &__header {
    margin-bottom: $telia-spacing-16;
  }

  display: flex;
  justify-content: stretch;
  align-items: center;
  margin: 0;
  padding: 0;

  &__option {
    position: relative;
    flex: 1 1 auto;

    & + & {
      margin-left: $telia-spacing-12;
    }
  }
}

.sr-only {
  border: 0;
  clip-path: circle(0%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>
