import { corpPartyInformation } from "@telia/b2b-rest-client";
import type {
  GetOrganisationResponse,
  IndividualResponseDTO,
} from "@telia/b2b-rest-client/dist/corp-party-information";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";

export async function getIndividualByPartyId(partyId: string): Promise<IndividualResponseDTO> {
  const scopeId: string = await getScopeIdOrThrow();
  return corpPartyInformation.GetPartyControllerService.getIndividualByPartyId(scopeId, partyId);
}

export async function getIndividualByTscid(tscid: string): Promise<IndividualResponseDTO> {
  return corpPartyInformation.GetPartyByTscidControllerService.getIndividualByTscid(tscid);
}

export async function getOrganisation(orgNo: string): Promise<GetOrganisationResponse> {
  return corpPartyInformation.GetOrganisationControllerService.getOrganisation(orgNo);
}
