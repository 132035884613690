<template>
  <CheckoutCard :t-id="tId" :title="title" disabled />
</template>
<script>
import { defineComponent } from "vue";
import CheckoutCard from "./checkout-card.vue";

export default defineComponent({
  name: "CheckoutCardPlaceholder",
  components: { CheckoutCard },
  props: {
    title: { type: String },
    tId: { type: String },
  },
});
</script>
