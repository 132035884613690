import { corpBasketManagement } from "@telia/b2b-rest-client";
import type { DetachedBasketDTO } from "@telia/b2b-rest-client/dist/corp-open-basket-management";
import type { Lang } from "../typings/types";

export const getDetachedBasketById = async (
  basketId: string,
  language: Lang
): Promise<DetachedBasketDTO> => {
  return corpBasketManagement.OpenBasketControllerService.getBasketById1(basketId, language);
};
