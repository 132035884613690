<template>
  <div class="create-myb-account-card">
    <telia-heading
      :key="componentRef"
      t-id="create-myb-account__heading"
      class="create-myb-account__heading"
      tag="h2"
      variant="title-200"
    >
      {{ t("mybusinessotpcheckout.createMybAccountHeading") }}
    </telia-heading>
    <fieldset class="create-myb-account">
      <legend class="sr-only">Följ och hantera ditt bredband i MyBusiness</legend>

      <div t-id="create-myb-account__description" class="create-myb-account__description">
        {{ t("mybusinessotpcheckout.createMybAccountDescription") }}
      </div>

      <div class="create-myb-account__checkbox">
        <telia-checkbox
          t-id="create-myb-account__checkbox"
          :value="mybAccountChecked"
          :checked="mybAccountChecked"
          @change="handleToggleCheck"
        >
          <telia-p>{{ t("mybusinessotpcheckout.createMybAccountCheckboxLabel") }}</telia-p>
        </telia-checkbox>
        <telia-p
          class="create-myb-account__terms-and-conditions-container"
          variant="additional-100"
        >
          {{ t("CREATE_MYB_ACCOUNT_TERMS_AND_CONDITION") }}
          <telia-link
            t-id="create-myb-account__terms-and-conditions-link"
            href="/foretag/mybusiness/om/villkor-mybusiness"
            target="_blank"
            >{{ t("CREATE_MYB_ACCOUNT_TERMS_AND_CONDITION_LINK_TEXT") }}
          </telia-link>
        </telia-p>
      </div>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../locale";
import { Ref, ref } from "vue";

const emit = defineEmits<{
  (e: "create-account", mybAccountChecked: boolean): void;
}>();
const componentRef = ref(0);
const t = translateMixin.methods.t;
const mybAccountChecked: Ref<boolean> = ref(true);

loadTranslations();
emit("create-account", mybAccountChecked.value);

async function loadTranslations() {
  await translateSetup(["mybusinessotpcheckout"]);
  componentRef.value++;
}

function handleToggleCheck() {
  mybAccountChecked.value = !mybAccountChecked.value;
  emit("create-account", mybAccountChecked.value);
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/borders/mixins";
@import "~@teliads/components/foundations/colors/tokens";
@import "@teliads/components/foundations/borders/tokens";

.create-myb-account-card {
  background: $telia-white;
  border-radius: 1.6rem;
  padding: $telia-spacing-32;
}

.create-myb-account {
  border: none;

  &__container {
    display: grid;
    gap: $telia-spacing-16;
  }

  &--horizontal {
    grid-template-columns: repeat(auto-fit, minmax(13.2rem, 1fr));
  }

  &__heading {
    margin-bottom: $telia-spacing-16;
  }

  &__option-heading {
    margin-bottom: $telia-spacing-24;
  }
  &__terms-and-conditions-container {
    margin-top: $telia-spacing-8;
    color: var(--purpur-color-transparent-black-600);
  }
  &__description {
    margin-bottom: $telia-spacing-32;

    :deep(ul) {
      margin-top: $telia-spacing-32;
      padding-left: $telia-spacing-24;
    }
  }
  &__info-link {
    margin-bottom: $telia-spacing-32;
  }
}

.sr-only {
  border: 0;
  clip-path: circle(0%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>
