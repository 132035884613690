<template>
  <checkout-card :title="t('CHECKOUT_VERIFIED_TITLE')" class="verified-signatory__container">
    <template #checkout-card-heading-button-slot>
      <telia-status-badge
        v-if="showVerifiedBadge"
        class="verified-signatory__badge"
        t-id="verified-signatory__badge"
        variant="positive"
        >{{ t("CHECKOUT_CONTACT_VERIFIED") }}
      </telia-status-badge>
    </template>
    <div t-id="verified-signatory">
      <div
        t-id="verified-signatory__contact-container"
        class="verified-signatory__contact-container"
      >
        <div v-if="name">
          <telia-p t-id="verified-signatory__name"
            ><strong>{{ t("CHECKOUT_CONTACT_NAME") }}</strong></telia-p
          >
          <telia-p class="verified-signatory__user" t-id="verified-signatory__user">
            {{ name }}
          </telia-p>
        </div>

        <div v-if="organizationName && organizationNumber">
          <telia-p
            ><strong>{{ t("CHECKOUT_CONTACT_ORGANISATION_DETAILS") }}</strong></telia-p
          >
          <telia-p t-id="verified-signatory__organization">
            {{ organizationName }}, {{ formattedOrganizationNumber }}
          </telia-p>
        </div>
      </div>
    </div>

    <div class="verified-signatory__notification-container">
      <telia-notification
        class="verified-signatory__existing-agreement"
        t-id="verified-signatory__existing-agreement"
        v-if="hasAgreement"
        heading-tag="h3"
        variant="inline"
        status="warning"
      >
        <span slot="heading">{{ t("BANK_ID_LOGIN_URL_ERROR_TITLE") }}</span>
        <telia-p slot="content">
          <telia-p>
            {{ t("CHECKOUT_CONTACT_EXISTING_AGREEMENT", { orgName: organizationName }) }}
          </telia-p>
          <telia-link
            t-id="verified-signatory__existing-agreement-button"
            href="/foretag/mybusiness/skaffa-mybusiness"
          >
            {{ t("CHECKOUT_CONTACT_EXISTING_AGREEMENT_BUTTON") }}
          </telia-link>
        </telia-p>
      </telia-notification>
    </div>

    <telia-button
      t-id="verified-signatory__verify-button"
      class="verified-signatory__button"
      variant="secondary"
      type="submit"
      @click="emit('change-organisation')"
    >
      {{ t("CHANGE_ORGANISATION") }}
    </telia-button>
  </checkout-card>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";

import CheckoutCard from "./checkout-card.vue";
import { translateMixin } from "../locale";
import { formatOrganizationNumber } from "../utils/formatting-utils";

const t = translateMixin.methods.t;

const emit = defineEmits<{
  (e: "change-organisation"): void;
}>();

interface Props {
  name?: string;
  organizationName?: string;
  organizationNumber?: string;
  hasAgreement?: boolean;
  showVerifiedBadge: boolean;
}

const props = defineProps<Props>();

const formattedOrganizationNumber: ComputedRef<string | undefined> = computed(() => {
  return formatOrganizationNumber(props.organizationNumber);
});
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/tokens";

.verified-signatory {
  &__container {
    margin-bottom: $telia-spacing-24;
  }

  &__contact-container {
    margin-bottom: $telia-spacing-24;
  }

  &__existing-agreement {
    telia-p {
      margin: $telia-spacing-8 0;
    }
  }

  &__notification-container {
    margin-bottom: $telia-spacing-32;
  }

  &__badge {
    float: right;
  }

  &__user {
    margin-bottom: $telia-spacing-24;
  }
}
</style>
