<template>
  <telia-notification
    heading-tag="h3"
    variant="inline"
    :status="alertMessage?.status"
    :t-id="`message-presenter__${alertMessage?.tId}`"
  >
    <span slot="heading">
      <telia-visually-hidden>{{ t(alertAccessibilityTitle) }}</telia-visually-hidden>
      {{ t(alertTitle) }}
    </span>
    <telia-text-spacing slot="content">
      <telia-p>
        {{ t(alertMessage?.message) }}
      </telia-p>
      <slot name="additionalContent"></slot>
    </telia-text-spacing>
  </telia-notification>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";
import { translateMixin, translateSetup } from "../locale";
import { messages } from "./messages";

interface Props {
  messageType?: string;
}

translateSetup();
const t = translateMixin.methods.t;

const props = defineProps<Props>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const alertMessage: ComputedRef<Record<string, any> | null> = computed(() => {
  return props.messageType ? messages[props.messageType] : null;
});
const alertTitle: ComputedRef<string> = computed(() => {
  return alertMessage.value?.title;
});

const alertAccessibilityTitle: ComputedRef<string> = computed(() => {
  return alertMessage.value?.accessibleTitle || alertTitle.value || "";
});
</script>

<style scoped></style>
