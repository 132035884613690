<template>
  <div class="address-input">
    <b2b-address-search
      t-id="address-input__address-lookup"
      type="search"
      :manual-address-enabled="true"
      :default-address="JSON.stringify(defaultAddress)"
      :scope-id="scopeId"
      :debounce-timeout-ms="800"
      :required="true"
      :label="t('BILLING_ACCOUNT_FORM_ADDRESS_LABEL')"
      :additional-address-information-enabled="true"
      openPages
      @addressSelected.native="handleAddressSelected"
      @modeChanged.native="handleModeChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { translateMixin, translateSetup } from "../../locale";
import { getParamsFromUrl } from "../../utils/urlUtils";
import type { AddressRequestDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import type { DefaultAddress, InputModeValues } from "../../typings/types";
import "@telia/b2b-address-search";
import "@telia/b2b-autocomplete-input";
import "@telia/b2x-spinner";

translateSetup();
const t = translateMixin.methods.t;

interface Props {
  defaultAddress?: DefaultAddress;
}

defineProps<Props>();

const emit = defineEmits<{
  (e: "address-selected", address: AddressRequestDTO): void;
  (e: "input-mode-changed", inputMode: InputModeValues): void;
}>();

const urlParams = getParamsFromUrl();
const scopeId = urlParams?.scopeId;

function handleAddressSelected(event: CustomEvent) {
  emit("address-selected", event.detail);
}

function handleModeChanged(event: CustomEvent) {
  emit("input-mode-changed", event.detail);
}
</script>

<style scoped></style>
