<template>
  <div>
    <telia-row class="multiple-billing-account-detail__row">
      <telia-col width="12" width-md="6">
        <telia-heading
          t-id="multiple-billing-account-detail__item-name"
          class="multiple-billing-account-detail__item-name"
          tag="h5"
          variant="title-100"
          >{{ basketItem.name }}
        </telia-heading>
        <div class="multiple-billing-account-detail__badge-container">
          <Badge
            v-if="showCollectiveInformation"
            t-id="broadband-item-details__collective-badge"
            variant="information"
            :showIcon="false"
            :allyLabel="t('BASKET_ITEM_COLLECTIVE_ALLY')"
          >
            {{ t("BASKET_ITEM_COLLECTIVE_BADGE") }}
          </Badge>
        </div>
        <telia-p>{{ basketItem.installationAddress?.fullAddress }}</telia-p>
      </telia-col>
      <telia-col width="12" width-md="6">
        <b2x-input-suggestions
          :t-id="`multiple-billing-account-detail-${basketItem.id}`"
          :suggestions="JSON.stringify(billingAccountSuggestions)"
          :value="searchTerm"
          :label="t('BILLING_ACCOUNT_MULTIPLE_DETAILS_SELECTION_LABEL')"
          required
          @focus="setBillingAccountSuggestions"
          @blur="restoreSelectedBillingAccount"
          @inputValue="filterBillingAccountsBySearchTerm"
          @suggestionSelected="handleBillingAccountSelected"
        ></b2x-input-suggestions>
      </telia-col>
    </telia-row>
    <telia-row
      v-if="selectedBillingAccount"
      t-id="multiple-billing-account-detail__information-container"
      class="multiple-billing-account-detail__information-container"
    >
      <telia-col width="12">
        <telia-heading tag="h6" variant="subsection-100"
          >{{ t("BILLING_ACCOUNT_BILLING_ACCOUNT_INFORMATION_LABEL") }}
        </telia-heading>
      </telia-col>
      <telia-col width="12" width-md="6">
        <div class="multiple-billing-account-detail__address-container">
          <telia-p v-for="(billingAccountAddressField, idx) in addressFields" :key="idx">
            {{ billingAccountAddressField }}
          </telia-p>
        </div>
        <telia-p>{{ deliveryMethod }}</telia-p>
      </telia-col>
      <telia-col width="12" width-md="6">
        <telia-p v-for="(billingAccountContactField, idx) in contactFields" :key="idx">
          {{ billingAccountContactField }}
        </telia-p>
      </telia-col>
    </telia-row>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, Ref, ref } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import { filterBillingAccounts } from "../../utils/filter-utils";
import { removeEmptyValues, valueOrNull, identity } from "../../utils/fp";
import type { BroadbandItemDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import type { BillingAccountDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import "@telia/b2x-input-suggestions";
import {
  getBillingAccountAddressFields,
  getBillingAccountContactFields,
} from "../../utils/billing-account-information-extraction";
import type { BillingAccountId } from "../../utils/BillingAccountManager";
import { Badge as PurpurBadge } from "@purpurds/purpur";
import { applyPureReactInVue } from "veaury";

const t = translateMixin.methods.t;
translateSetup();

const Badge = applyPureReactInVue(PurpurBadge);

interface Props {
  basketItem: BroadbandItemDTO;
  billingAccounts: BillingAccountDTO[];
  selectedBillingAccountId?: BillingAccountId;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "select-billing-account", payload: [BillingAccountDTO, BroadbandItemDTO]): void;
}>();
const searchTerm: Ref<string> = ref(
  toSuggestionFromBillingAccountId(props.selectedBillingAccountId)
);
const billingAccountSuggestions: Ref<string[]> = ref([]);
const selectedBillingAccount: Ref<BillingAccountDTO | null> = ref(
  valueOrNull(getBillingAccountById(props.selectedBillingAccountId))
);
const deliveryMethodTranslationMap = {
  LETTER: "BILLING_ACCOUNTS_DELIVERY_METHOD_LETTER",
  EMAIL: "BILLING_ACCOUNTS_DELIVERY_METHOD_EMAIL",
  SMS: "BILLING_ACCOUNTS_DELIVERY_METHOD_SMS",
} as const;

function filterBillingAccountsBySearchTerm(event: CustomEvent): void {
  searchTerm.value = event.detail;
  setBillingAccountSuggestions();
}

function getBillingAccountById(id: BillingAccountId | undefined): BillingAccountDTO | undefined {
  return props.billingAccounts.find((ba: BillingAccountDTO) => ba.id === id);
}

function toSuggestionFromBillingAccountId(id: BillingAccountId | undefined): string {
  const billingAccount = getBillingAccountById(id);
  if (billingAccount) {
    return toBillingAccountSuggestion(billingAccount);
  }
  return "";
}

const deliveryMethod: ComputedRef<string> = computed(() => {
  return selectedBillingAccount.value?.deliveryMethod
    ? t(deliveryMethodTranslationMap[selectedBillingAccount.value?.deliveryMethod])
    : "";
});

const addressFields: ComputedRef<string[]> = computed(() => {
  return selectedBillingAccount.value
    ? getBillingAccountAddressFields(selectedBillingAccount.value)
    : [];
});

const contactFields: ComputedRef<string[]> = computed(() => {
  return selectedBillingAccount.value
    ? getBillingAccountContactFields(selectedBillingAccount.value)
    : [];
});

const showCollectiveInformation: ComputedRef<boolean> = computed(() => {
  return props.basketItem.fttxAgreementType === "KFÄ3";
});

function toBillingAccountSuggestion(billingAccount: BillingAccountDTO): string {
  return [billingAccount.id, billingAccount.reference].filter(removeEmptyValues).join(" / ");
}

function handleBillingAccountSelected(event: CustomEvent): void {
  const value = event.detail;
  searchTerm.value = value;
  const billingAccount = props.billingAccounts.find(
    (billingAccount) => toBillingAccountSuggestion(billingAccount) === value
  ) as BillingAccountDTO;
  selectedBillingAccount.value = billingAccount;
  emit("select-billing-account", [selectedBillingAccount.value, props.basketItem]);
}

function restoreSelectedBillingAccount(): void {
  searchTerm.value = selectedBillingAccount.value
    ? toBillingAccountSuggestion(selectedBillingAccount.value)
    : "";
}

function setBillingAccountSuggestions(): void {
  billingAccountSuggestions.value = filterBillingAccounts(
    props.billingAccounts,
    undefined,
    searchTerm.value
  ).map(toBillingAccountSuggestion);
}
</script>

<style lang="scss" scoped>
@import "@purpurds/purpur/styles";

.multiple-billing-account-detail {
  &__item-name {
    margin-bottom: var(--purpur-spacing-100);
  }

  &__address-container {
    margin-bottom: var(--purpur-spacing-400);
  }

  &__badge-container {
    margin-bottom: var(--purpur-spacing-100);
  }

  &__information-container {
    margin-top: var(--purpur-spacing-400);
  }
}
</style>
