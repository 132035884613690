import { ref, Ref } from "vue";
import { corpLogin } from "@telia/b2b-rest-client";

interface IsLoggedInComposable {
  isLoggedIn: Ref<boolean>;
}

export function useIsLoggedIn(): IsLoggedInComposable {
  const isLoggedIn: Ref<boolean> = ref(false);

  (async function getLoggedInStatus() {
    try {
      const {
        isLoggedIn: isLoggedInResponse,
      } = await corpLogin.IsLoggedInControllerService.isLoggedIn();
      isLoggedIn.value = isLoggedInResponse as boolean;
    } catch (e) {
      // Ignore error
    }
  })();
  return {
    isLoggedIn,
  };
}
