<template>
  <div class="checkout-card" :class="[{ 'checkout-card--active': active }, bottomMarginClass]">
    <div class="checkout-card__header">
      <telia-heading
        class="checkout-card__title"
        :class="{ 'checkout-card__title--disabled': disabled }"
        tag="h3"
        :variant="variant"
      >
        {{ title }}
      </telia-heading>
      <div class="checkout-card__button-container">
        <slot name="checkout-card-heading-button-slot"></slot>
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";

const bmClassMap = {
  sm: "checkout-card--bm-sm",
  md: "checkout-card--bm-md",
  lg: "checkout-card--bm-lg",
};

interface Props {
  title: string;
  headingSize?: "md" | "lg";
  active?: boolean;
  disabled?: boolean;
  bottomMargin?: "sm" | "md" | "lg";
}

const props = withDefaults(defineProps<Props>(), { headingSize: "md", bottomMargin: "lg" });
const variant: ComputedRef<string> = computed(() => {
  return props.headingSize === "md" ? "title-200" : "title-300";
});
const bottomMarginClass: ComputedRef<string> = computed(
  () => bmClassMap[props.bottomMargin] ?? bmClassMap.lg
);
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/colors/tokens";
@import "~@teliads/components/foundations/typography/tokens";

.checkout-card {
  background: $telia-white;
  padding: $telia-spacing-32;
  border-radius: $telia-border-radius-16;

  &--active {
    border-color: $telia-black;
  }

  &--bm-sm {
    padding-bottom: $telia-spacing-8;
  }

  &--bm-md {
    padding-bottom: $telia-spacing-16;
  }

  &--bm-lg {
    padding-bottom: $telia-spacing-32;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $telia-spacing-24;
  }

  &__title {
    flex: 1 1 auto;

    &--disabled {
      color: $telia-typography-color-weak;
    }
  }

  &__button-container {
    flex: 0 0 auto;
  }
}
</style>
